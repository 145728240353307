import moment, { invalid } from "moment"
import { startsWith, drop, keys } from "ramda"
import { TimePeriod } from "./interfaces"
import { isNull } from "util"

const CONTRIBUTION_KEY_WORD = "scenarioresource_contribution"
const VALUE_KEY_WORD = "scenarioresource_value"

/**
 * returns a list of the time periods
 * the list containts; startDate, endDate, name, current, id and index(id)
 * 
 * id needs to be int based (for saving current index)
 * 
 * @param csvdata the period csv data
 */
const mapTimePeriods = (csvdata: any[]): TimePeriod[] => {

  //check for US formatting
  let USFormat = false
  let dateTest = moment(csvdata[0].period_from, "D-MM-YYYY").format("YYYY-MM-DD")
  if(dateTest === "Invalid date"){
    dateTest = moment(csvdata[0].period_from, "YYYY-MM-DD").format("YYYY-MM-DD")
    if(dateTest === "Invalid date"){
      console.log("Invalid date format")
    }else{
      USFormat = true
      console.log("importing US date formatting")
    }
  }

  const mappedData = csvdata.map(p => {
    if(USFormat){
      return {
        startDate: moment(p.period_from, "YYYY-MM-DD").format("YYYY-MM-DD"),
        endDate: moment(p.period_to, "YYYY-MM-DD").format("YYYY-MM-DD"),
        name: p.period_name,
        id: p.period_id,
        index: p.period_id,
        current: parseInt(p.period_start) === 1 ? true : false
      }
    }else{
      return {
        startDate: moment(p.period_from, "D-MM-YYYY").format("YYYY-MM-DD"),
        endDate: moment(p.period_to, "D-MM-YYYY").format("YYYY-MM-DD"),
        name: p.period_name,
        id: p.period_id,
        index: p.period_id,
        current: parseInt(p.period_start) === 1 ? true : false
      }
    }
   
  })

  return mappedData
}

/**
 *
 *
 * @param param0 the current csv data and the existing state {data:any[], state:any}
 */
const parseTimePeriods = ({ data, state }: { data: any[]; state: any }) => {
  let success = true
  let timePeriods
  let currentTimePeriod = null
  try {
    timePeriods = mapTimePeriods(data)

    // check we have vaild dates and grab current period (if set)
    timePeriods.forEach(period => {
      if (
        period.startDate === "Invalid date" ||
        period.endDate === "Invalid date"
      ) {
        console.log("Invalid date - " + period.name)
        success = false
      }
      if (period.current) {
        currentTimePeriod = parseInt(period.index)
      }
    })
  } catch (error) {
    console.log(error)
    success = false
  }

  // filter for coloumns with the contribution type (max, min)
  const resources = keys(data[0]).filter(x =>
    startsWith(CONTRIBUTION_KEY_WORD, x.toString())
  )
  const indexes = resources.map(x =>
    drop(CONTRIBUTION_KEY_WORD.length, x.toString())
  )

  //
  const filteredIndexes = indexes.filter(
    index =>
      data[0][`${CONTRIBUTION_KEY_WORD}${index}`] !== "" ||
      data[0][`${VALUE_KEY_WORD}${index}`] !== ""
  )

  //
  if (!data[0] || !filteredIndexes || !filteredIndexes.length) {
    console.log("filtered Indexes Error")
    success = false
  }

  // check that dates exist
  timePeriods

  const constraints = filteredIndexes.map(i => {
    const newRes = {
      con_mm: data[0][`scenarioresource_contribution${i}`],
      fromVal: data[0][`scenarioresource_rangefrom${i}`],
      toVal: data[0][`scenarioresource_rangeto${i}`],
      value: data[0][`scenarioresource_value${i}`],
      valueCoversRange:
        data[0][`scenarioresource_valuecoversrange${i}`] === "1"
          ? "true"
          : "false",
      name: "",
      unit: ""
    }
    return newRes
  })
  if (!success) {
    return
  }
  return { timePeriods, constraints, currentTimePeriod }
}

export { parseTimePeriods }
