import * as React from "react"
import { observer, inject } from "mobx-react"
import PermissionStore from "../mobx/PermissionStore"
import ConfigStore, { ConfigProps } from "../mobx/ConfigStore"
import { getCurrentScenarios, getConfig } from "../utils/getData"
import { deleteScenario } from "../utils/deleteData"
import { withRouter, RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"

import Paper from "material-ui/Paper"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table"
import IconButton from "material-ui/IconButton"
import PieChart from "material-ui/svg-icons/editor/pie-chart"
import Tune from "material-ui/svg-icons/image/tune"
import Delete from "material-ui/svg-icons/action/delete"
import AutoComplete from "material-ui/AutoComplete"
import Dialog from "material-ui/Dialog"
import FlatButton from "material-ui/FlatButton"
import UiStore from "../mobx/UiStore"
import { loadLogo } from "../utils/loadData"

export interface State {
  mounted: boolean
  scenarios: any
  search: string
  deleteOpen: boolean
  targetScenario: number
}

export interface IProps {}

type Props = IProps & RouteComponentProps<{}>
interface InjectedProps extends Props {
  permissionStore: PermissionStore
  configStore: ConfigStore
  uiStore: UiStore
}

const styles: { [key: string]: React.CSSProperties } = {
  buttonColumn: {
    width: "10%",
    minWidth: "64px",
    paddingLeft: "12px",
    paddingRight: "12px",
    alignContent: "center"
  }
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    configStore: store.configStore
  }
})
@observer
export class Scenarios extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      mounted: false,
      scenarios: undefined,
      search: "",
      deleteOpen: false,
      targetScenario: 0
    }
  }

  public async componentDidMount() {
      await this.loadScenarios()
      await this.loadConfig()
      this.setState({ mounted: true })
  }

  private loadScenarios = async () => {
    const { permissionStore } = this.injected
    if (permissionStore.user) {
      const token = permissionStore.getToken()
      const data = await getCurrentScenarios(token)
      this.setState({ scenarios: data.scenarios })
    }
  }
  private loadConfig = async () => {
    const { permissionStore, configStore } = this.injected

    if (permissionStore.user) {
      const token = permissionStore.getToken()
      // TODO save config ID somewhere else
      const config = await getConfig(token, "1")
      // make sure we got a config not error
      if (config.error != "config not Found") {
        // not quite sure why this needs to be double parsed but it does
        const parsed = JSON.parse(JSON.parse(config.configuration))
        const newConfig: ConfigProps = {
          maturityMap: parsed.maturityMap,
          companyId: parsed.companyId,
          configId: parsed.configId,
          heatMap: parsed.heatMap,
          primaryLogo: parsed.primaryLogo,
          partnerLogo: parsed.partnerLogo
        }
        configStore.setConfig(newConfig)
        //load the logo in
        try {
          // let logo = await loadLogo(configStore.primaryLogo, token)
          // this.injected.configStore.primaryLogoURL = URL.createObjectURL(logo)
          
          // let partnerLogo = await loadLogo(configStore.partnerLogo, token)
          // if(partnerLogo != ''){
          //   this.injected.configStore.partnerLogoURL = URL.createObjectURL(partnerLogo)
          // }
        } catch {
          //
        }
      }
    }
  }

  private onClickRow = (row: any) => {
    // TODO discuss what to do here
  }

  private closeDelete = () => {
    this.setState({ deleteOpen: false })
  }
  private openDelete = (id: number) => {
    this.setState({ deleteOpen: true, targetScenario: id })
  }
  private onClickDelete = async () => {
    const { permissionStore } = this.injected
    try {
      if (permissionStore.user) {
        const token = permissionStore.user.identity.idToken
        const data = await getCurrentScenarios(token)
        this.setState({ scenarios: data.scenarios })
        await deleteScenario(this.state.targetScenario, token)
      }
    } catch {
      console.log(
        "onClickDelete Error - " + this.state.targetScenario.toString()
      )
    }
    await this.loadScenarios()
    this.closeDelete()
  }

  private handleSearchUpdate = (value: string) => {
    this.setState({ search: value.toLowerCase() })
  }

  public render(): JSX.Element {
    if (!this.state.mounted) {
      return <div />
    }
    const ScenarioConfig = {
      text: "name",
      value: "name"
    }
    const actions = [
      <FlatButton
        key="deleteCancel"
        label="Cancel"
        primary={true}
        onClick={this.closeDelete}
      />,
      <FlatButton
        key="deleteConfirm"
        label="Delete"
        primary={true}
        onClick={this.onClickDelete}
      />
    ]
    const targetScenario = this.state.scenarios.filter(
      (scenario: any) => scenario.id === this.state.targetScenario
    )[0]
    let target = ""
    if (targetScenario !== undefined) {
      target = targetScenario.name
    }

    return (
      <div>
        <div style={{ width: "20%", margin: "auto" }}>
          <h1 style={{ marginBottom: "0" }}>Scenarios </h1>
          <AutoComplete
            floatingLabelText="Filter"
            filter={AutoComplete.caseInsensitiveFilter}
            maxSearchResults={5}
            dataSource={this.state.scenarios}
            dataSourceConfig={ScenarioConfig}
            fullWidth={true}
            onUpdateInput={this.handleSearchUpdate}
          />
        </div>

        <Table onRowSelection={this.onClickRow}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn> Scenario</TableHeaderColumn>
              <TableHeaderColumn>Description</TableHeaderColumn>
              <TableHeaderColumn style={styles.buttonColumn}>
                Radar View
              </TableHeaderColumn>
              <TableHeaderColumn style={styles.buttonColumn}>
                Gantt Chart View
              </TableHeaderColumn>
              {/* <TableHeaderColumn>Views</TableHeaderColumn> */}
              <TableHeaderColumn style={styles.buttonColumn}>
                Delete
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.state.scenarios.map((scenario: any, id: any) => {
              let display = ""
              if (
                !(scenario.name.toLowerCase().indexOf(this.state.search) > -1)
              ) {
                display = "none"
              }
              const onClickfn = () => this.openDelete(scenario.id)
              const deleteDisabled = scenario.isBaseline
              return (
                <TableRow key={id} style={{ display }}>
                  <TableRowColumn>{scenario.name}</TableRowColumn>
                  <TableRowColumn>
                    <div style={{ textOverflow: "ellipsis" }} />
                    {scenario.description}
                  </TableRowColumn>
                  <TableRowColumn style={styles.buttonColumn}>
                    <Link to={`scenarios/${scenario.id}/radar`}>
                      <IconButton>
                        <PieChart />
                      </IconButton>
                    </Link>
                  </TableRowColumn>
                  <TableRowColumn style={styles.buttonColumn}>
                    <Link to={`scenarios/${scenario.id}/gantt`}>
                      <IconButton>
                        <Tune />
                      </IconButton>
                    </Link>
                  </TableRowColumn>
                  <TableRowColumn style={styles.buttonColumn}>
                    <Link to={"dashboard"}>
                      <IconButton onClick={onClickfn} disabled={deleteDisabled}>
                        <Delete />
                      </IconButton>
                    </Link>
                  </TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Dialog
          title={"Confirm Deletion"}
          modal={false}
          actions={actions}
          open={this.state.deleteOpen}
        >
          {"Are you sure you want to permanently delete " + target + " ?"}
        </Dialog>
      </div>
    )
  }
}

export default withRouter(Scenarios)
