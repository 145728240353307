import * as React from "react"
import * as accounting from "accounting"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"
//@ts-ignore
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent"

import UiStore from "../mobx/UiStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import PermissionStore from "../mobx/PermissionStore"

import { getSolutions, ScenarioToOptimise } from "../utils/ProjectSolution"
import CustomLineChart from "../components/CustomLineCharts"
import { amber700 } from "material-ui/styles/colors"
import { NumberBeautify } from "../utils/NumberBeautify"
import { compareToProjects, CompareDiffrence } from "../utils/SolutionUtils"

import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import TextField from "material-ui/TextField"
import FlatButton from "material-ui/FlatButton"
import CircularProgress from "material-ui/CircularProgress"
import Toggle from "material-ui/Toggle"
import { projectAttributeTotal } from "../mobx/Scenario"
import ConfigStore from "../mobx/ConfigStore"

export interface ConstraintListItem {
  text: string
  value: number
  propertyName: string
}
export interface Props { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

export interface State {
  value: number
  minValue: string
  maxValue: string
  steps: number
  xAxis: string
  yAxis: string
  chartData: any[]
  loading: boolean
  loaded: boolean
  graphWidth: number
  graphHeight: number
  constraintList: ConstraintListItem[]
  selectedProjectProperty: string
  compareToBase: boolean
  drop: number
}

const CustomToolTip = (props: any, compareToBase: boolean) => {
  if (props.payload[0] != null) {
    let additions = ""
    let removals = ""
    if (compareToBase) {
      if (props.payload[0].payload.diffBase.length > 0) {
        props.payload[0].payload.diffBase.forEach((diff: any) => {
          if (diff.alive === true) {
            additions += diff.code + " , "
          } else {
            removals += diff.code + " , "
          }
        })
      }
    } else {
      if (props.payload[0].payload.diffLast.length > 0) {
        props.payload[0].payload.diffLast.forEach((diff: any) => {
          if (diff.alive === true) {
            additions += diff.code + " , "
          } else {
            removals += diff.code + " , "
          }
        })
      }
    }
    const newPayload = [...props.payload]
    if (typeof newPayload[0].value === "number") {
      newPayload[0].value = NumberBeautify.numberFormat(newPayload[0].value, 1)
    }
    if (typeof newPayload[1].value === "number") {
      newPayload[1].value = NumberBeautify.numberFormat(newPayload[1].value, 1)
    }

    if (additions.length > 0) {
      newPayload.push({
        name: "Added",
        value: additions,
        color: "#4CAF50"
      })
    }
    if (removals.length > 0) {
      newPayload.push({
        name: "Removed",
        value: removals,
        color: "#F44336"
      })
    }
    return (
      <DefaultTooltipContent {...props} label={null} payload={newPayload} />
    )
  }
  return <DefaultTooltipContent {...props} />
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    configStore: store.configStore
  }
})
@observer
export class SensitivityReportTab extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      value: 0,
      minValue: "0",
      maxValue: "0",
      steps: 10,
      graphHeight: 45,
      graphWidth: 45,
      xAxis: "Duration",
      yAxis: "Portfolio Values",
      chartData: [],
      constraintList: [],
      selectedProjectProperty: "duration",
      loaded: false,
      loading: false,
      compareToBase: false,
      drop: -1
    }
    this.handleChange = this.handleChange.bind(this)
  }

  private handleChange(event: any) {
    if (this.state.selectedProjectProperty === "duration") {
      const periods = toJS(this.injected.baselineStore.periods)
      if (event.target.name === "maxValue") {
        const value = Math.floor(parseInt(event.target.value, 0))
        this.setState({ maxValue: Math.min(value, periods.length).toString() })
      }
      if (event.target.name === "minValue") {
        const value = Math.floor(event.target.value)
        this.setState({ minValue: value.toString() })
      }
    } else {
      const unformattedValue = this.isExpenditureSelected()
        ? accounting.unformat(event.target.value).toString()
        : event.target.value
      const name = event.target.name
      if (name === "minValue") {
        this.setState({ minValue: unformattedValue })
      }
      if (name === "maxValue") {
        this.setState({ maxValue: unformattedValue })
      }
      if (name === "steps") {
        this.setState({ steps: event.target.value })
      }
    }
  }

  private handleConstraintChange = (event: any, index: any, value: number) => {
    const newProjectProperty =
      this.state.constraintList && this.state.constraintList[value].propertyName
    this.setState(
      {
        value,
        xAxis: "Project " + this.state.constraintList[value].text,
        selectedProjectProperty: newProjectProperty
      },
      () => this.updateValues()
    )
  }

  private updateValues = () => {
    if (this.state.selectedProjectProperty) {
      const baseValue =
        this.state.selectedProjectProperty === "duration"
          ? this.injected.scenariosStore.selectedProject.duration
          : this.injected.scenariosStore.selectedProject.attributeTotal(this.state.selectedProjectProperty)
          // this.injected.scenariosStore.selectedProject.attributes.find(
          //   (attr: { name: string }) =>
          //     attr.name === this.state.selectedProjectProperty
          // ).value
      const minValue = baseValue
      const maxValue =
        this.state.selectedProjectProperty === "duration"
          ? Math.floor(baseValue * 1.5)
          : baseValue * 1.5
      this.setState({
        minValue: minValue.toString(),
        maxValue: maxValue.toString()
      })
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    this.injected.baselineStore.constraints.map((item, index) => {
      this.state.constraintList.push({
        text: item.name,
        value: index,
        propertyName: item.name
      })
    })
    this.state.constraintList.push({
      text: "Duration",
      value: this.state.constraintList.length - 1,
      propertyName: "duration"
    })
    this.setState({ value: this.state.constraintList.length - 1 })
    this.updateValues()
  }

  public componentWillUnMount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  private updateDimensions = () => {
    // magic numbers
    const barDiv = document.getElementById("barDiv")
    const chartDiv = document.getElementById("chartDiv")
    if (barDiv && chartDiv) {
      const height = 600 - barDiv.offsetHeight
      const width = chartDiv.offsetWidth
      if (height > 0 && width > 0) {
        this.setState({
          graphHeight: height,
          graphWidth: width
        })
      }
    }
  }

  private calculateSensitivityReport = async () => {
    const authToken = this.injected.permissionStore.getToken()
    const canOptimise = this.injected.permissionStore.canOptimise()
    const forceRefresh = this.injected.configStore.forceRefresh
    if(!canOptimise){
      return
    }
    let cur = parseInt(this.state.minValue, 0)
    const attrIndex = this.injected.scenariosStore.selectedProject.attributes.findIndex(
      (attr: { name: { toLowerCase: () => string } }) =>
        attr.name.toLowerCase() ===
        this.state.selectedProjectProperty.toLowerCase()
    )
    const projectIndex = this.injected.scenariosStore.working.codeToIndex(
      this.injected.scenariosStore.selectedProjectCode
        ? this.injected.scenariosStore.selectedProjectCode
        : ""
    )
    let solutionArray: any[] = []
    let steps = this.state.steps
    let amount =
      (parseInt(this.state.maxValue, 0) - parseInt(this.state.minValue, 0)) /
      (steps - 1)

    if (this.state.selectedProjectProperty === "duration") {
      steps =
        parseInt(this.state.maxValue, 0) - parseInt(this.state.minValue, 0) + 1
      amount = 1
      this.setState({
        steps,
        loading: true
      })
    } else {
      this.setState({ loading: true })
    }

    for (let step = 0; step < steps; step++) {
      const newScenario = toJS(this.injected.scenariosStore.working)
      const periods = this.injected.baselineStore.periods.length
      
      if (this.state.selectedProjectProperty === "duration") {
        newScenario.projects[projectIndex].duration = cur
      } else {
        //newScenario.projects[projectIndex].attributes[attrIndex].value = cur
        newScenario.projects[projectIndex].attributes[attrIndex].values[0]= cur /newScenario.projects[projectIndex].duration 
      }
      const solutionArrayItem = await ScenarioToOptimise(
        newScenario,
        authToken,
        periods,
        forceRefresh
      )
      solutionArray.push(solutionArrayItem)

      cur += amount
    }
    solutionArray = await getSolutions(solutionArray, authToken)
    solutionArray = solutionArray.map(item => {
      const solution = item.status === "error" ? "infeasable" : item.solution
      return {
        jobId: item.jobId,
        status: item.status,
        solution
      }
    })
    this.updateChartData(solutionArray)
  }

  private updateChartData = (solutionArray: any) => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    const dataArray: any[] = []
    const AddAmount =
      (parseInt(this.state.maxValue, 0) - parseInt(this.state.minValue, 0)) /
      (this.state.steps - 1)
    const currentProjects = this.injected.scenariosStore.working.projects
    let drop = -1

    for (let i = 0; i < solutionArray.length; i++) {
      let solutionBenefits: number = 0
      let stepValue =
        Math.floor((parseInt(this.state.minValue, 0) + AddAmount * i) * 100) /
        100
      let counter3: number = 0
      let diffBase = Array()
      let diffLast = Array()

      let included = false
      if (
        solutionArray[i].solution !== "infeasable" &&
        solutionArray[i].solution !== "error"
      ) {
        const solutionItem = solutionArray[i].solution.projects
        solutionItem.forEach((solution: { project: string }) => {
          currentProjects.forEach(project => {
            if (project.code === solution.project) {
              solutionBenefits += project.getBenefit()

              const projectExpenditure = project.attributeTotal("Expenditure")
              // const projectExpenditure = project.attributeTotal("Expenditure")

              // TODO -- Needs to use diffrenet periods value

              // if project is included
              if (selectedProject.code === solution.project) {
                included = true
                if (this.state.selectedProjectProperty === "duration") {
                  let expenditure =  project.getAttribute("Expenditure",0);
                  if(expenditure !== undefined){
                  counter3 += expenditure * (parseInt(this.state.minValue, 0) + i)
                  }else{
                    counter3 = 0;
                  }
                
                } else if (
                  this.state.selectedProjectProperty.toLowerCase() ===
                  "expenditure"
                ) {
                  counter3 += stepValue * project.duration
                } else {
                  counter3 += projectExpenditure
                }
              } else {
                counter3 += projectExpenditure
              }
            }
          })
        })
        diffBase = compareToProjects(
          solutionItem,
          this.injected.scenariosStore.working.projects
        )
        if (i > 0) {
          diffLast = CompareDiffrence(diffBase, dataArray[i - 1].diffBase)
        }
      } else {
        solutionBenefits = 0
        stepValue = 0
        counter3 = 0
      }
      if (!included && drop < 0) {
        drop = stepValue
      }

      dataArray.push({
        step: i,
        x: stepValue,
        y: solutionBenefits,
        x3: counter3,
        diffBase,
        diffLast
      })
    }
    this.setState({
      chartData: dataArray,
      loading: false,
      loaded: true,
      drop
    })
    this.updateDimensions()
  }

  private compareToBaseToggle = (event: any, isInputChecked: boolean) => {
    this.setState({ compareToBase: isInputChecked })
  }
  private isExpenditureSelected = () => {
    const { selectedProjectProperty } = this.state
    return selectedProjectProperty.toLowerCase() === "expenditure"
  }

  public render(): JSX.Element {
    const canOptimise = this.injected.permissionStore.canOptimise()
    let body = <div />
    let steps = <div />
    const { minValue, maxValue } = this.state
    const formattedMinValue = this.isExpenditureSelected()
      ? accounting.formatMoney(minValue, "$", 0)
      : minValue
    const formattedMaxValue = this.isExpenditureSelected()
      ? accounting.formatMoney(maxValue, "$", 0)
      : maxValue

    const compareToBaseLabel = this.state.compareToBase
      ? "Compare to Scenario"
      : "Compare to Previous Step"
    const CustomToolTipFN = (props: any) =>
      CustomToolTip(props, this.state.compareToBase)
    if (this.state.loading) {
      body = (
        <div style={styles.loading}>
          <CircularProgress size={200} thickness={7} />
        </div>
      )
    } else if (this.state.loaded) {
      body = (
        <CustomLineChart
          width={this.state.graphWidth}
          height={this.state.graphHeight}
          chartData={this.state.chartData}
          CustomToolTip={CustomToolTipFN}
          yAxisDataKey={undefined}
          yAxisLabel={this.state.yAxis}
          xAxisDataKey={"x"}
          xAxisLabel={this.state.xAxis}
          drop={this.state.drop}
          lines={[
            {
              dataKey: "y",
              name: "Portfolio Benefit",
              stroke: "#8884d8"
            },
            {
              dataKey: "x3",
              name: "Portfolio Cost",
              stroke: amber700
            }
          ]}
        />
      )
    }
    if (this.state.selectedProjectProperty !== "duration") {
      steps = (
        <TextField
          floatingLabelText="Steps"
          name="steps"
          value={this.state.steps}
          onChange={this.handleChange}
          style={styles.textField}
        />
      )
    }

    return (
      <div>
        <div>
          <SelectField
            floatingLabelText="Property"
            value={this.state.value}
            onChange={this.handleConstraintChange}
          >
            {this.state.constraintList.map((item, index) => {
              return (
                <MenuItem key={index} value={index} primaryText={item.text} />
              )
            })}
          </SelectField>
          <Toggle
            label={compareToBaseLabel}
            toggled={this.state.compareToBase}
            onToggle={this.compareToBaseToggle}
            thumbStyle={styles.thumb}
            trackStyle={styles.track}
            thumbSwitchedStyle={styles.thumb}
            trackSwitchedStyle={styles.track}
          />
          <div id={"barDiv"}>
            <TextField
              floatingLabelText="Min Value"
              name="minValue"
              value={formattedMinValue}
              onChange={this.handleChange}
              style={styles.textField}
            />
            <TextField
              floatingLabelText="Max Value"
              name="maxValue"
              value={formattedMaxValue}
              onChange={this.handleChange}
              style={styles.textField}
            />
            {steps}

            <FlatButton
              label="Calculate"
              onClick={this.calculateSensitivityReport}
              primary={true}
              disabled={!canOptimise}
            />
          </div>
        </div>
        <div id={"chartDiv"}>{body}</div>
      </div>
    )
  }
}

const styles: any = {
  textField: {
    marginLeft: "10px",
    width: "150px"
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  track: { backgroundColor: "#E0E0E0" },
  thumb: { backgroundColor: "#F5F5F5" }
}
