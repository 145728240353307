import * as React from "react"
import { observer, inject } from "mobx-react"

import PermissionStore from "../../../mobx/PermissionStore"
import { getCurrentScenarios, getScenario } from "../../../utils/getData"

import FlatButton from "material-ui/FlatButton"
import Toggle from "material-ui/Toggle"
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
  } from "material-ui/Table"
import {MenuItem, SelectField } from "material-ui"


import CircularProgress from "material-ui/CircularProgress"
import { ScenarioProps } from "../../../mobx/Scenario"
import { compareScenario } from "../../../utils/comparison"
import { loadAndFormatScenario } from "../../../utils/loadData"
import { projectStatusToString } from "../../../utils/projectState"
import CostLegend from "../../RightSideComponents/CostLegend"

import { exportToPrint, printSetup } from "../../../utils/exportToPrint"
import ConfigStore from "../../../mobx/ConfigStore"

type Props = {
  scenarios: any
}
interface InjectedProps extends Props {
    permissionStore: PermissionStore,
    configStore: ConfigStore
}

export interface State {
  loaded: boolean
  scenario1Index: number
  scenario2Index: number
  scenario1: ScenarioProps | undefined
  scenario2: ScenarioProps | undefined
  showDifference: boolean
}

@inject(({store}) =>{
    return{
        permissionStore: store.permissionStore,
        configStore: store.configStore
    }
})

@observer
export class DetailedComparison extends React.Component<Props,State>{
    private get injected(){
        return this.props as InjectedProps
    }
    constructor(props: Props){
        super(props)
        this.state = {
            loaded: false,
            scenario1Index: 0,
            scenario2Index: 1,
            scenario2: undefined,
            scenario1: undefined,
            showDifference: false
        }
    }
    public async componentDidMount() {
        this.refreshScenarios(0,1)
    }
  
    
    //controls if we show all details on only the differences between the two scenarios
    private onShowDifference = (event: any) =>{
       this.setState({showDifference : !this.state.showDifference})  
    }

    // changes to the summary view
    private toSummaryClick = (event: any) =>{

    }
    /**
     * changes scenario ID and sets loaded to false, triggering refreshScenarios on next render
     * @param event 
     * @param index 
     * @param value 
     */
    private onFirstScenarioChange = async (event: any, index: any, value: any) =>{
        //make sure you can't compare to same project
        if(value != this.state.scenario2Index){
            this.setState({ scenario1Index: value,loaded: false})
        }
        this.refreshScenarios(value, this.state.scenario2Index)
    }
    /**
     * changes scenario ID and sets loaded to false, triggering refreshScenarios on next render
     * @param event 
     * @param index 
     * @param value 
     */
    private onSecondScenarioChange = async (event: any, index: any, value: any) =>{
        //make sure you can't compare to same project
        if(value != this.state.scenario1Index){
            this.setState({ scenario2Index: value, loaded: false})
        }  
        this.refreshScenarios(this.state.scenario1Index, value)
    }
    /**
     * checks if scenarios are undefined or if current ID doesn't match selected
     * if either is true, reloads that scenario
     * sets loaded = true at end of function
     */
    private refreshScenarios = async(scenario1: number, scenario2: number) =>{

        const scenario1ID = this.props.scenarios[scenario1].id        
        const scenario2ID = this.props.scenarios[scenario2].id
        if(this.state.scenario1 === undefined || this.state.scenario1.id !== scenario1ID){
            const scenarioData = await this.loadFullScenario(scenario1ID)
            this.setState({scenario1: scenarioData})
        }
        if(this.state.scenario2 === undefined || this.state.scenario2.id !== scenario2ID){
            const scenarioData = await this.loadFullScenario(scenario2ID)
            this.setState({scenario2: scenarioData})
        }

        this.setState({loaded:true})
    }

    private loadFullScenario = async(id:number) =>{
        const {
            permissionStore,
          } = this.injected
          const token = permissionStore.getToken()
          const scenarioData = await loadAndFormatScenario(id, token)

          return scenarioData
    }

    private print = () => {
        

        let printContents:any, popupWin: any;
        let heading = "Scenario Comparison"

        let printElements =document.getElementById('print-section')
        printContents = printElements != null ? printElements.innerHTML : "";

        let setup: printSetup = {title : heading, subHeading:"",
            printContents : printContents, logoURL: this.injected.configStore.primaryLogo}
        
        exportToPrint(setup)
       
    }

    /**
     * handles the creation of a row for the table to simplify and make sure styling is constant
     * @param name the values being displayed, used in first and 3 column
     * @param value1 the first value, used in second column
     * @param value2 the second value, used in forth column
     */
    private createRow = (name: string, value1: string, value2: string, level: number = 1)=> {
        let style = {}
        let styleLeft:any = {
            borderLeft: "1px solid #E0E0E0"}
        let nameArray = [name, name]

        switch (level) {
            case 0:
                style ={
                    fontWeight: "bold"}
                styleLeft.fontWeight = "bold"
                styleLeft.borderLeft =""
                nameArray[1]=""
                break;
            case 1:
                //default 
                break;
            case 2:
                style = {
                    paddingLeft: "44px"
                }
                styleLeft.paddingLeft = "44px"
                break
            case 3:
            style = {
                paddingLeft: "66px"
            }
            styleLeft.paddingLeft = "66px"
            break
            default:
                break;
        }
        return (
        <TableRow>
        <TableRowColumn style={style}> {nameArray[0]}</TableRowColumn>
        <TableRowColumn>{value1}</TableRowColumn>
        <TableRowColumn style={styleLeft}>{nameArray[1]}</TableRowColumn>
        <TableRowColumn>{value2} </TableRowColumn>
        </TableRow>
        )
    }


    public render(): JSX.Element{
        const scenario1 = this.state.scenario1
        const scenario2 = this.state.scenario2
        const scenarios = this.props.scenarios

        let rowStyle = styles.hidden
        let exportDisabled = true
        const showDifference = this.state.showDifference
        if(scenarios.length > 1){
            rowStyle = styles.row
        }
        let tableData : {name:string, value1:any, value2:any, level: number}[] = []
        /**
         * Levels
         * 0: heading (bold)
         * 1: no indent
         * 2: + 20px padding
         */
        //show loading icon default
        let table = <div 
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
            <CircularProgress size={200} thickness={7} />
        </div>

        //make sure we have data
        if(scenario2 !== undefined && scenario1 !== undefined && this.state.loaded == true){
            //compare scenarios to get difference
            let difference = compareScenario(scenario1, scenario2)
            //create data to be iterated though for table
                //currentPeriod
                if(showDifference && difference.currentPeriod || !showDifference ){
                    tableData.push({name:"Current Period",value1:scenario1.currentPeriod, value2: scenario2.currentPeriod, level:1 })
                }
                //constraints
                for (let c = 0; c < scenario1.constraints.length; c++) {
                    let index = difference.constraints.findIndex((con:any) => con.name === scenario1.constraints[c].name)
                    if(showDifference && index != -1  || !showDifference ){
                        tableData.push({name: "Constraint: " + scenario2.constraints[c].name,value1:"",value2:"", level:0})
                        if(showDifference && difference.constraints[index].value || !showDifference ){
                            tableData.push({name:"Value",value1:scenario1.constraints[c].value, value2: scenario2.constraints[c].value, level:2})
                        }
                        if(showDifference && difference.constraints[index].valueCoversRange || !showDifference ){
                            tableData.push({name:"Value covers Range", value1: scenario1.constraints[c].valueCoversRange.toString(), value2:scenario2.constraints[c].valueCoversRange.toString(), level:2})
                        }
                    }
                    
                }
                //Projects
                let scenario1Unique = scenario1.projects.filter(p=>  !scenario2.projects.some(b => b.code == p.code))
                let scenario2Unique = scenario2.projects.filter(p=> !scenario1.projects.some(b => b.code == p.code))

                for(let p = 0; p < scenario1.projects.length; p++){
                    let diffIndex = difference.projects.findIndex((proj:any) => proj.code === scenario1.projects[p].code)
                    let index2 = scenario2.projects.findIndex((proj:any) => proj.code === scenario1.projects[p].code)
                    if(showDifference && (diffIndex != -1)  || !showDifference ){
                        tableData.push({name:"Project: "+ scenario1.projects[p].name, value1:"", value2:"",level:0})
                        if(showDifference && difference.projects[diffIndex].status || !showDifference){
                            tableData.push({name:"Status: ",value1: projectStatusToString(scenario1.projects[p].status),value2: index2 == -1? "" :projectStatusToString( scenario2.projects[index2].status), level:1})
                        }
                        if(showDifference && difference.projects[diffIndex].mandatory || !showDifference){
                            tableData.push({name:"Mandatory: ",value1: scenario1.projects[p].mandatory,value2: index2 == -1? "" :scenario2.projects[index2].mandatory, level:2})
                        }
                        if(showDifference && difference.projects[diffIndex].alive || !showDifference){
                            tableData.push({name:"Alive: ",value1: scenario1.projects[p].alive.toString(),value2: index2 == -1? "" :scenario2.projects[index2].alive.toString(), level:2})
                        }
                        if(showDifference && difference.projects[diffIndex].duration || !showDifference){
                            tableData.push({name:"Duration: ",value1: scenario1.projects[p].duration.toString(),value2: index2 == -1? "" :scenario2.projects[index2].duration.toString(), level:1})
                        }
                        if(showDifference && difference.projects[diffIndex].startPeriod || !showDifference){
                            tableData.push({name:"Scheduled start period: ",value1: scenario1.projects[p].startPeriod.toString(),value2: index2 == -1? "" :scenario2.projects[index2].startPeriod.toString(), level:1})
                        }
                        //hide if empty
                        if((showDifference && difference.projects[diffIndex].startPeriods || !showDifference) && (scenario1.projects[p].startPeriods.length > 0 || (index2 != -1 &&scenario2.projects[index2].startPeriods.length > 0 ))){
                            tableData.push({name:"Possible start periods: ",value1: scenario1.projects[p].startPeriods.toString(),value2: index2 == -1? "" :scenario2.projects[index2].startPeriods.toString(), level:1})
                        }
                        //hide if empty                        
                        if((showDifference && difference.projects[diffIndex].prerequisites !== undefined&&difference.projects[diffIndex].prerequisites.length >= 1 || !showDifference)&& 
                            (scenario1.projects[p].prerequisites.length >= 1|| (index2 != -1 && scenario2.projects[index2].prerequisites.length >= 1))){
                            tableData.push({name:"Pre-Requisites:",value1:"",value2:"",level:1})
                            for(let r = 0; r < scenario1.projects[p].prerequisites.length; r++){
                                let PreReqIndex2 = scenario2.projects[index2].prerequisites.findIndex((proj:any) => proj.name === scenario1.projects[p].prerequisites[r].name)
                                tableData.push({name: scenario1.projects[p].prerequisites[r].name + ": ",value1: scenario1.projects[p].prerequisites[r].type,value2: PreReqIndex2 == -1? "Not Found" :scenario2.projects[index2].prerequisites[PreReqIndex2].type, level:2})
                            };
                        }
                        if(showDifference && (difference.projects[diffIndex].benefit|| index2 == -1) || !showDifference){
                            tableData.push({name:"Benefits:", value1:scenario1.projects[p].benefits.total(), value2:index2 == -1? "" :scenario2.projects[index2].benefits.total(), level:2})
                            // tableData.push({name:"Benefits:",value1:"",value2:"",level:1})
                            // tableData.push({name:"Total:", value1:scenario1.projects[p].benefits.total(), value2:index2 == -1? "" :scenario2.projects[index2].benefits.total(), level:2})
                            //         if(index2 === -1 || scenario1.projects[p].duration >= scenario2.projects[index2].duration)
                            //         for (let d = 0; d < scenario1.projects[p].duration; d++){
                            //             tableData.push({name:"Period "+ (d +1)+":", value1:scenario1.projects[p].benefits.values[d], 
                            //             value2:index2 == -1? 
                            //                 "" :
                            //                 scenario2.projects[index2].benefits.values.length >= d? 
                            //                     scenario2.projects[index2].benefits.values[d]:
                            //                     "",
                            //              level:3})
                            //         }else if (index2 !== -1){
                            //             for (let d = 0; d < scenario2.projects[p].duration; d++){
                            //                 tableData.push({name:"Period "+ (d +1)+":",
                            //                  value1:scenario1.projects[p].benefits.values.length >= d?scenario1.projects[p].benefits.values[d]:"",
                            //                  value2: scenario2.projects[index2].benefits.values[d], 
                            //                  level:3})
                            //             }
                            //         }

                        }

                        if(showDifference && ( difference.projects[diffIndex].attributes!== undefined ||  difference.projects[diffIndex].attributes.length > 0) || !showDifference){
                            for( let attr = 0; attr < scenario1.projects[p].attributes.length; attr ++ ){
                                let name = scenario1.projects[p].attributes[attr].name
                                let attrDiff =-1
                                if(diffIndex != -1){
                                     attrDiff = difference.projects[diffIndex].attributes.findIndex((attr:any) => attr.ID == name)
                                }
                                if(showDifference && attrDiff != -1  && difference.projects[diffIndex].attributes[attrDiff].value 
                                    || showDifference && diffIndex == -1
                                    || !showDifference){
                                    tableData.push({name:name, value1:"", value2:"", level:1})
                                    tableData.push({name:"Total:", value1:scenario1.projects[p].attributes[attr].total(), value2:index2 == -1? "" :scenario2.projects[index2].attributes[attr].total(), level:2})
                                    if(index2 === -1 || scenario1.projects[p].duration >= scenario2.projects[index2].duration)
                                    for (let d = 0; d < scenario1.projects[p].duration; d++){
                                        tableData.push({name:"Period "+ (d +1)+":", value1:scenario1.projects[p].attributes[attr].values[d], 
                                        value2:index2 == -1? 
                                            "" :
                                            scenario2.projects[index2].attributes[attr].values.length >= d? 
                                                scenario2.projects[index2].attributes[attr].values[d]:
                                                "",
                                         level:3})
                                    }else if (index2 !== -1){
                                        for (let d = 0; d < scenario2.projects[p].duration; d++){
                                            tableData.push({name:"Period "+ (d +1)+":",
                                             value1:scenario1.projects[p].attributes[attr].values.length >= d?scenario1.projects[p].attributes[attr].values[d]:"",
                                             value2: scenario2.projects[index2].attributes[attr].values[d], 
                                             level:3})
                                        }
                                    }
                                }
                                
                            }
                        }

                    }
                }
                //show projects that are only in scenario2
                    for(let p = 0; p < scenario2Unique.length; p++){
                            tableData.push({name:"Project: "+ scenario2Unique[p].name, value1:"", value2:"",level:0})
                                tableData.push({name:"Status: ",value1: "",value2: projectStatusToString( scenario2Unique[p].status), level:1})
                                tableData.push({name:"Mandatory: ",value1: "", value2: scenario2Unique[p].mandatory, level:2})
                                tableData.push({name:"Alive: ",value1: "",value2: scenario2Unique[p].alive.toString(), level:2})
                                tableData.push({name:"Duration: ",value1: "",value2: scenario2Unique[p].duration.toString(), level:1})
                                tableData.push({name:"Scheduled start period: ",value1: "",value2: scenario2Unique[p].startPeriod.toString(), level:1})
                                if(scenario2Unique[p].startPeriods.length > 0){
                                    tableData.push({name:"Possible start periods: ",value1: "",value2: scenario2Unique[p].startPeriods.toString(), level:1})
                                }
                                if(scenario2Unique[p].prerequisites.length > 0){
                                    tableData.push({name:"Pre-Requisites:",value1:"",value2:"",level:1})
                                    for(let r = 0; r < scenario2Unique[p].prerequisites.length; r++){
                                        tableData.push({name: scenario2Unique[p].prerequisites[r].name + ": ",value1: "",value2: scenario2Unique[p].prerequisites[r].type, level:2})
                                    };
                                }
                                tableData.push({name:"Benefit", value1:"", value2:scenario2Unique[p].benefits.total(), level:1})
                                // tableData.push({name:"Total:", value1:"", value2: scenario2Unique[p].benefits.total(), level:2})
                                // for (let d = 0; d < scenario2Unique[p].duration; d++){
                                //     tableData.push({name:"Period "+ (d +1)+":", value1:"", value2:scenario2Unique[p].benefits.values[d], level:3})
                                // }
                               for( let attr = 0; attr < scenario2Unique[p].attributes.length; attr ++ ){
                                    let name = scenario2Unique[p].attributes[attr].name
                                    tableData.push({name:name, value1:"", value2:"", level:1})
                                    tableData.push({name:"Total:", value1:"", value2: scenario2Unique[p].attributes[attr].total(), level:2})
                                    for (let d = 0; d < scenario2Unique[p].duration; d++){
                                        tableData.push({name:"Period "+ (d +1)+":", value1:"", value2:scenario2Unique[p].attributes[attr].values[d], level:3})
                                    }
                                }
                                        
                                    


                    }
            //enable Export button
                    exportDisabled = false
            //create table
            table = (
                <div id="print-section">
            <Table style={styles.table} >
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                    <TableHeaderColumn tooltip={scenario1.description} > {scenario1.name}</TableHeaderColumn>

                    <TableHeaderColumn tooltip={scenario2.description}>{scenario2.name}
                    </TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false} showRowHover={true} >
                    {tableData.map((tableRow: any, id:any) => {
                            return(
                                this.createRow(tableRow.name, tableRow.value1, tableRow.value2, tableRow.level)
                                )       
                        })            
                    }
                </TableBody>
            </Table>
            </div>
            )
        }
        

        return(
            <div>
                <div style={styles.centered}>
                    <Toggle label={"Only show Difference"}
                    onToggle={this.onShowDifference}
                    toggled={showDifference} 
                    ></Toggle>
                    <FlatButton onClick={this.print} disabled = {exportDisabled} >
                    Export to PDF
                    </FlatButton>
                </div>
                <div style={rowStyle}>
                    <div style={styles.twoColumn}>
                        <div style={styles.centered}>
                        <SelectField 
                            floatingLabelText="First Scenario"
                            onChange= {this.onFirstScenarioChange}
                            value = {this.state.scenario1Index}>
                                {scenarios.map(
                                    (scenario:any, scenarioIndex:any) =>{
                                        let disabled = false
                                        if(scenarioIndex == this.state.scenario2Index){
                                            disabled = true
                                        }
                                        return(
                                            <MenuItem
                                            key={scenarioIndex}
                                            value ={scenarioIndex}
                                            primaryText={scenario.name}
                                             disabled = {disabled}
                                            />
                                        )
                                    }
                                )}
                            </SelectField>
                        </div>
                       
                    </div>
                    
                    <div style={styles.twoColumn}>
                        <div style={styles.centered}>
                            <SelectField 
                            floatingLabelText="Second Scenario"
                            onChange= {this.onSecondScenarioChange}
                            value = {this.state.scenario2Index}>
                                {scenarios.map(
                                    (scenario:any, scenarioIndex:any) =>{
                                        let disabled = false
                                        if(scenarioIndex == this.state.scenario1Index){
                                            disabled = true
                                        }
                                        return(
                                            <MenuItem
                                            key={scenarioIndex}
                                            value ={scenarioIndex}
                                            primaryText={scenario.name}
                                             disabled = {disabled}
                                            />
                                        )
                                    }
                                )}
                            </SelectField>
                        </div>
                    </div>
                </div> 
                {table}
            </div> 
        )
    }
}

const styles: any = {
    centered: {
        width: "20%", 
        margin: "auto",
        textAlign: "center"
    },
    row: {
      display: "flex",

    },
    twoColumn: {
        flex: "50%",
        marginTop:"50px",
        marginRight:"5px",
        marginLeft:"5px"
    },
    hidden: {
        display: "none",        
    },
    borderLeft:{
        borderLeft: "1px solid #E0E0E0"
    },
    table:{
        minHeight: "50px"
    }

  }


export default DetailedComparison