import {
  Scenario,
  OptimisationParameter,
  projectAttributeTotal,
} from "../mobx/Scenario"
import Project, { Mandatory, Prerequisites, status } from "../mobx/Project"
import project from "ramda/es/project"
import { duration } from "moment"
import { styles } from "../components/Header/styles"
import attribute from "../mobx/Attribute"
import { calculateStatus } from "./projectState"

const isStatusPending = (status: string): boolean => {
  return !(
    status === "infeasable" ||
    status === "solved" ||
    status === "cancelled" ||
    status === "not_found" ||
    status === "error"
  )
}

/**
 * requests results from submitted optimizations back from the server
 * allows for the batch jobing of requests
 * @param solutionArray the array of ids for the server to check for results for
 * @param authToken the authorization token to be used for acuitas server
 */
export async function getSolutions(solutionArray: any[], authToken: string) {
  let solved = false
  let attempts = 0
  const maxAttempts = 10
  while (!solved) {
    try {
      const ids = solutionArray
        .filter((item) => isStatusPending(item.status) && !item.solution)
        .map((item) => item.jobId)
      if (!ids.length) {
        solved = true
      } else {
        const solutions = await getArrayOfSolutions(ids, authToken)
        solutions.response.forEach(
          (solution: { status: string; solution: any; id: any }) => {
            if (!isStatusPending(solution.status) && solution.solution) {
              const index = solutionArray.findIndex(
                (item) => item.jobId === solution.id
              )
              solutionArray[index] = solution
            }
          }
        )

        const idsRefreshed = solutionArray.filter(
          (item) => isStatusPending(item.status) && !item.solution
        )

        if (!idsRefreshed.length) {
          solved = true
        } else {
          if (attempts < maxAttempts) {
            attempts++
            await delay()
          } else {
            throw new Error("timeout")
          }
        }
      }
    } catch (error) {
      console.log(error)
      if (attempts < maxAttempts) {
        attempts++
        await delay()
      } else {
        solutionArray = solutionArray.map((item) => {
          if (isStatusPending(item.status) && !item.solution) {
            return {
              jobId: item.jobId,
              status: "error",
              solution: "infeasable",
            }
          }
          return item
        })
      }
    }
  }

  return solutionArray
}
const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms))

async function delay() {
  await timeout(1500)
}

/**
 * Converts a scenario to a format for the optimizer and sends it
 *
 * @param scenario the scenario to be formatted and sent
 * @param authToken the authorization token to be used for acuitas server
 * @param periods number of periods to optimise over
 *
 * @returns an id for the submitted job on the server
 */
export async function ScenarioToOptimise(
  scenario: Scenario,
  authToken: any,
  periods: number,
  forceRefresh: boolean
) {
  // create the base structure for the object that will be sent to biarri
  const sendobj: {
    periods: any[]
    projects: any[]
    constraints: any[]
    attributes: any[]
    parameters: any
    refresh: any
  } = {
    periods: [],
    projects: [],
    constraints: [],
    attributes: [],
    parameters: {},
    refresh: false
  }

  //update with forcedRefresh
  if(forceRefresh){
    sendobj.refresh = true
  }

  // fill the object
  // periods -- start at currentPeriod rather then 0.
  for (let index = scenario.currentPeriod; index < periods + 1; index++) {
    sendobj.periods.push({ index })
  }

  // map projects
  for (let p = 0; p < scenario.projects.length; p++) {
    calculateStatus(
      scenario.projects[p], scenario.currentPeriod)
    let project: any
    // find and split projects
    if (scenario.projects[p].inFlight) { //flag should include in-flight, completed and suspended projects
        if (scenario.projects[p].status === status.completed){
          continue // skip this project
        }
        if( scenario.projects[p].status === status.in_flight ||scenario.projects[p].status === status.suspended ){
          //split based on start period
          let currentRunTime = scenario.currentPeriod - scenario.projects[p].startPeriod
          let SplitDuration = scenario.projects[p].duration - currentRunTime
          
          project = projectMapping(
            scenario.projects[p].code,
             SplitDuration, 
             scenario.projects[p].mandatory === Mandatory.Mandatory,
             getBenefit(scenario, p, periods),[] )
            //can only start current period
          project.start_periods = scenario.currentPeriod
        }
    //not inflight
    } else if (scenario.projects[p].status === status.candidate|| scenario.projects[p].status === status.scheduled) {

        project = projectMapping(
          scenario.projects[p].code,
          parseInt(scenario.projects[p].duration.toString(), 10), 
           scenario.projects[p].mandatory === Mandatory.Mandatory,
           getBenefit(scenario, p, periods),[] )
      if (scenario.projects[p].startPeriods.length > 0) {
        let vaildPeriods: number[] = []
        scenario.projects[p].startPeriods.forEach(startPeriod => {
          if(startPeriod >= scenario.currentPeriod){
            vaildPeriods.push(startPeriod)
          }
        });
        if(vaildPeriods.length > 0){
          project.start_periods = vaildPeriods
        }
      }
    }
    // --------Perquisites -----------------------
    // done for all above scenarios
    // Needs to check that a prerequisite isn't mandatory
    const prerequisites = scenario.projects[p].prerequisites
    if (prerequisites && prerequisites.length > 0) {
      project.prerequisites = prerequisites.map((req) => {
        let index = scenario.projects.findIndex(
          (project:Project) => project.code === req.name)
        // error if we couldn't find it
        if(index === -1){
          console.log("Invalid Pre-requisites, Project: " + project.code + " preReq: " + req.name)
          return // don't add it
        }
          // check if the prerequisite has being completed
        if(index === -1 || scenario.projects[index].status === status.completed){
          return //if so, don't add it
        }
        return {
          name: req.name.toString(),
          relationship: req.type,
        }
        
      })
    }
    sendobj.projects.push(project)
  }
  // map constraints
  scenario.constraints.map((constraint: any, constraintIndex: number) => {
    // exclude non current periods
    const sendConstraint = {
      name: constraint.name,
      style: constraint.con_mm,
      value: parseFloat(constraint.value),
      period: {
        from: parseInt(constraint.fromVal, 10),
        to: parseInt(constraint.toVal, 10),
        value_covers_range:
          constraint.valueCoversRange === "true" ||
          constraint.valueCoversRange === true,
      },
    }
    sendobj.constraints.push(sendConstraint)

    // // map attributes - this is done in constraints loop
    // scenario.projects.map((project) => {
    //   // group period data
    //   project.attributes.forEach(attribute => {
    //     let startPeriod = 0
    //     attribute.values.forEach((periodValue: number, index) => {
    //       if (periodValue !== attribute.values[startPeriod]) {
    //         //make the object
    //         const attributeObject = {
    //           project: project.code,
    //           constraint: attribute.name,
    //           // TODO sanitize input
    //           value: parseFloat(
    //             attribute.values[startPeriod].toString()
    //           ) || 0,
    //           // I think absolute is the wrong setting here but have being instructed to use it
    //           period: {
    //             from: startPeriod + 1,
    //             to: index + 1,
    //             style: 'relative',
    //           },
    //         }
    //         sendobj.attributes.push(attributeObject)

    //       }
    //     })
    //     //make final object -- look at moving this to a function
    //     const attributeObject = {
    //       project: project.code,
    //       constraint: attribute.name,
    //       // TODO sanitize input
    //       value: parseFloat(
    //         attribute.values[startPeriod].toString()
    //       ) || 0,
    //       // I think absolute is the wrong setting here but have being instructed to use it
    //       period: {
    //         from: startPeriod + 1,
    //         to: attribute.values.length,
    //         style: 'relative',
    //       },
    //     }
    //     sendobj.attributes.push(attributeObject)
    //   })
  })
  // -- end constraints loop

  // ignore per-period  !!-- when we fix this updated SensitivityReport --!!
  scenario.projects.map((project) => {
    project.attributes.map((attribute) => {
      const attributeObject = {
        project: project.code,
        constraint: attribute.name,
        // TODO sanitize input
        value: parseFloat(
          (attribute.values[0] ? attribute.values[0] : 0).toString()
        ),
        // TODO why do we hardcode it to full period? because the barrir engine is borked
        period: {
          from: 1,
          to: periods,
          style: "absolute",
        },
      }
      sendobj.attributes.push(attributeObject)
    })

  })

  // no parameters

  const dataObj = await sendJobToOptimise(sendobj, authToken)
  if (!dataObj.response.jobId) {
    throw new Error("no job id")
  }
  return dataObj.response
}

/**
 * Gets the value of the benefits to be used by barrir
 *  -- Benefit is the value optimized on by changing it we get different optimizations
 * @param scenario
 * @param p project ID number
 * @param periods the number of periods in the solution
 */
const getBenefit = (scenario: Scenario, p: number, periods: number): any[] => {
  // are we using max or min (engine always maximizes so make negative to "minimize")
  let multiplier = scenario.optimisationMode == "Max" ? 1 : -1
  let projectBenefit = 0
  let style = "absolute"
  // find the correct benefit to use
  switch (scenario.optimisationParameter) {
    case "benefit":
      let benefits = []
      for (let index = 1; index < periods + 1; index++) {
        benefits.push({ period: index, benefit: 0, style: style })
        let benefit = scenario.projects[p].getPeriodBenefit(index)

        if (benefit !== undefined) {
          benefits[index - 1].benefit = benefit * multiplier
        }
      }
      return benefits
      // let benefit = scenario.projects[p].getPeriodBenefit(0)
      // projectBenefit = benefit !== undefined ? benefit.value * multipler : 0
      break

    case "strategicIndex":
      projectBenefit =
        parseFloat(scenario.projects[p].strategicIndex.toString()) * multiplier
      break

    case "constraint":
      // sends the total value for the project
      let value = projectAttributeTotal(
        scenario.projects[p],
        scenario.optimisationAttribute
      )
      projectBenefit = value * multiplier
      break

    default:
      //otherwise return 0
      projectBenefit = 0
      break
  }
  return [{ benefit: projectBenefit, period: 1, style: style }]
}

const projectMapping = (name: string,duration: number, mandatory: Boolean, benefits: any, prerequisites: any): any => {
  let project = {
    name: name,
    // TODO sanitize input
    duration: duration,
    mandatory:mandatory,
    prerequisites: [],
    // get the correct benefit
    benefits: benefits,
  }
  return project
}

/**
 * sends an indivudal job to the optimiser and wait for a response
 * response wont contain optimization
 * @param jsonObj the job (or scenario) being sent to the optimizer
 * @param authToken the authorization token to be used for acuitas server
 */
async function sendJobToOptimise(
  jsonObj: any,
  authToken: string
): Promise<any> {
  return fetch(`${process.env.REACT_APP_API_URL}/biarri/jobs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify({ jsonObj }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Unable to generate authorization token")
      }
      return response.json()
    })
    .catch((error) => {
      throw new Error(error)
    })
}

/**
 *sends a groupd of jobs to the optimizer and waits for a response
 response wont containt optimization
 * @param ids the jobs (prepared scenarios) being sent to the optimizer
 * @param authToken the authorization token to be used for acuitas server
 */
async function getArrayOfSolutions(ids: any, authToken: string): Promise<any> {
  return fetch(`${process.env.REACT_APP_API_URL}/biarri/job/solution`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify({ ids }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Unable to generate authorization token")
      }
      return response.json()
    })
    .catch((error) => {
      throw new Error(error)
    })
}

// async function getJobSolutionById(jobId, authToken): Promise<any> {
//   return fetch(`${process.env.REACT_APP_API_URL}/biarri/job/` + jobId, {
//     method: 'GET',
//     headers: {
//       Authorization: 'Bearer ' + authToken
//     }
//   })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Unable to generate authorization token');
//       }
//       return response.json();
//     })
//     .catch(error => {
//       throw new Error(error);
//     });
// }

export default getBenefit
