import * as React from "react"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import Project, { Mandatory, Prerequisites, status } from "../mobx/Project"
import { attribute } from "../mobx/Attribute"
import { projectAttributeTotal } from "../mobx/Scenario"
import BaselineStore from "../mobx/BaselineStore"

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table"

import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import FlatButton from "material-ui/FlatButton"
import TextField from "material-ui/TextField"
import CircularProgress from "material-ui/CircularProgress"

import { ScenarioToOptimise, getSolutions } from "../utils/ProjectSolution"

import { isUndefined } from "util"
import Dialog from "material-ui/Dialog"
import Chip from "material-ui/Chip"
import Avatar from "material-ui/Avatar"
import Add from "material-ui/svg-icons/content/add"
import { Tab } from "material-ui"
import { promises } from "fs"
import Checkbox from "material-ui/Checkbox"
import { nextPossibleProjectState, projectStatusToString, updateStatus } from "../utils/projectState"
import { toNumber } from "lodash"
import { throws } from "assert"
import { updateAttributeLength } from "../utils/mobxUtils"
import { project } from "ramda"
import ConfigStore from "../mobx/ConfigStore"

export interface Props { }

export interface State {
  optimising: boolean
  newDuration: number
  newStatus: status
  showAddStartPeriod: boolean
  currentStartPeriod: number | null
  showPreRequisite: boolean
  currentPrerequisite: string | null
  currentPrerequisiteType: Prerequisites
  showDurationConfirm: boolean
  showStartPeriod:boolean
  showStatusError: boolean
}

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    configStore: store.configStore
  }
})
@observer
export class ProjectSettingsTab extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      optimising: false,
      newDuration: 1,
      newStatus: status.candidate,
      showAddStartPeriod: false,
      currentStartPeriod: null,
      showPreRequisite: false,
      currentPrerequisite: null,
      currentPrerequisiteType: Prerequisites.before,
      showDurationConfirm: false,
      showStartPeriod: false,
      showStatusError: false
    }
  }
  private dirtyScenarioStatus = () => {
    this.injected.scenariosStore.edit()
  }

  /**
   * updates the project duration and updates attributes to match the new length
   */
  private onDurationChange = (event: any, index: any, value: string) => {
    let newDuration = parseInt(value,10)
    const currentPeriod = this.injected.scenariosStore.working.currentPeriod
    const selectedProject =this.injected.scenariosStore.selectedProject
    const startPeriod = this.injected.scenariosStore.selectedProject.startPeriod
    if(currentPeriod > startPeriod + newDuration && selectedProject.status !== status.completed)
    {
      this.setState({newDuration : newDuration, showDurationConfirm: true, newStatus: status.completed})
      }
    else if(currentPeriod < startPeriod + newDuration && selectedProject.status === status.completed)
      {
        this.setState({newDuration : newDuration, showDurationConfirm: true, newStatus:status.in_flight })
    }else{
      this.updateDuration(newDuration);
    }
   
  }
  private updateDuration =(duration: number) =>{
    this.injected.scenariosStore.selectedProject.duration = duration
    for(let i = 0; i < this.injected.scenariosStore.selectedProject.attributes.length; i++){
      // let newValue = .updateLength(parseInt(value, 10),0)
      this.injected.scenariosStore.selectedProject.attributes[i].values= updateAttributeLength(this.injected.scenariosStore.selectedProject.attributes[i].values,duration)
    }
    // assume this is only shown if their is a status change
    if(this.state.showDurationConfirm){
      this.injected.scenariosStore.selectedProject.status = this.state.newStatus
    }
    this.dirtyScenarioStatus()
    this.forceUpdate()
  }

  private durationConfirm =() =>{
    this.updateDuration(this.state.newDuration)
    this.setState({showDurationConfirm: false})
  }

  private durationCancel =() => {
    this.setState({showDurationConfirm: false})
  }




  /**
   * handles the changing of constraints via textfield
   *
   * @param key the constraints name
   * @param period the period which is being changed
   * @param event the change event from the textfield
   */
  private constraintChange = async (
    key: any,
    period: number,
    event: { target: { value: string } }
  ) => {
    const selectedProject = this.injected.scenariosStore.selectedProject

    if (selectedProject) {
      const index = selectedProject.attributes.findIndex(
        (attribute: attribute) => attribute.name === key
      )
      // prevent NaN values
      let newValue = parseFloat(event.target.value) || 0
      selectedProject.attributes[index].values[period] = newValue
    }
    this.dirtyScenarioStatus()
    this.forceUpdate()
    this.setState({})
  }

  private onBenefitChange = (event: any) => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if (selectedProject) {
      // selectedProject.benefit = parseFloat(event.target.value)
      selectedProject.benefits.setPeriod(parseFloat(event.target.value), 0)
    }
    this.dirtyScenarioStatus()
  }

  private onStatusChange = (event: any, index: any, value: string) => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if(parseInt(value) === status.in_flight ){
      if(this.injected.scenariosStore.working.currentPeriod < selectedProject.startPeriod){
        //show error / pick start period
        this.setState({showStatusError:true, newStatus:parseInt(value)})
      }
    }
    else if(parseInt(value) === status.completed){
      if(this.injected.scenariosStore.working.currentPeriod < (selectedProject.startPeriod + selectedProject.duration)){
        //show error / pick start period
        this.setState({showStatusError:true, newStatus:parseInt(value) })
      }

    }
    else{
      if (selectedProject) {
        updateStatus(selectedProject, parseInt(value))
        // selectedProject.status = parseInt(value) as status
      }
      this.dirtyScenarioStatus()
    }
  }

  private statusConfirmCancel =() =>{
    this.setState({showStatusError: false})
  }

  //this needs to have either start period change / check
  private statusConfirmAccept =() =>{
    const selectedProject = this.injected.scenariosStore.selectedProject
    if(selectedProject){
      updateStatus(selectedProject, this.state.newStatus)
    }
    this.setState({showStatusError: false})
  }

  private updateMandatory = () => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if (selectedProject) {
      if (selectedProject.mandatory === Mandatory.Mandatory) {
        selectedProject.mandatory = Mandatory.nonMandatory
      } else {
        if (selectedProject.mandatory === Mandatory.nonMandatory) {
          selectedProject.mandatory = Mandatory.Mandatory
        }
      }
    }
    this.dirtyScenarioStatus()
  }
  private updateExcluded = () => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if (selectedProject) {
      if (selectedProject.mandatory === Mandatory.Excluded) {
        selectedProject.mandatory = Mandatory.nonMandatory
      } else {
        if (selectedProject.mandatory !== Mandatory.Excluded) {
          selectedProject.mandatory = Mandatory.Excluded
        }
      }
    }
    this.dirtyScenarioStatus()
  }

  private optimiseScenario = async () => {
    this.setState({ optimising: true })
    let solutionArray: any[] = []
    const authToken = this.injected.permissionStore.getToken()
    const canOptimise = this.injected.permissionStore.canOptimise()
    const forceRefresh = this.injected.configStore.forceRefresh
    if(!canOptimise){
      return
    }

    const periods = this.injected.baselineStore.periods.length
    const solutionArrayItem = await ScenarioToOptimise(
      this.injected.scenariosStore.working,
      authToken,
      periods,
      forceRefresh
    )
    solutionArray.push(solutionArrayItem)

    solutionArray = await getSolutions(solutionArray, authToken)
    solutionArray = solutionArray.map((item) => {
      const solution = item.status === "error" ? "infeasable" : item.solution
      return {
        jobId: item.jobId,
        status: item.status,
        solution,
      }
    })
    this.injected.scenariosStore.setOptimised(solutionArray)

    this.setState({ optimising: false })
  }
  private openStartPeriod = (event: any) => {
    this.setState({ showAddStartPeriod: true })
  }
  private startPeriodAdd = (event: any) => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if (
      !selectedProject.startPeriods.find(
        (startPeriod: number) => startPeriod === this.state.currentStartPeriod
      ) &&
      this.state.currentStartPeriod !== null
    ) {
      selectedProject.startPeriods.push(this.state.currentStartPeriod)
    }
    this.startPeriodClose(event)
  }
  private startPeriodClose = (event: any) => {
    this.setState({ showAddStartPeriod: false, currentStartPeriod: null })
  }
  private removeStartPeriod = (index: number) => {
    this.injected.scenariosStore.selectedProject.startPeriods.splice(index, 1)
  }

  private onStartPeriodChange = (
    event: any,
    index: any,
    value: number | null
  ) => {
    this.setState({ currentStartPeriod: value })
  }
  private openPreRequisite = (event: any) => {
    this.setState({ showPreRequisite: true })
  }
  private removePreRequisite = (index: number) => {
    this.injected.scenariosStore.selectedProject.prerequisites.splice(index, 1)
  }
  private prerequisiteClose = (event: any) => {
    this.setState({
      showPreRequisite: false,
      currentPrerequisite: null,
      currentPrerequisiteType: Prerequisites.before,
    })
  }
  private prerequisiteAdd = (event: any) => {
    const selectedProject = this.injected.scenariosStore.selectedProject
    if (
      !selectedProject.prerequisites.find(
        (prerequisite: any) =>
          prerequisite.name === this.state.currentPrerequisite
      ) &&
      this.state.currentPrerequisite !== null
    ) {
      selectedProject.prerequisites.push({
        name: this.state.currentPrerequisite,
        type: this.state.currentPrerequisiteType,
      })
    }

    this.dirtyScenarioStatus()
    this.prerequisiteClose(event)
  }

  private onPrerequisitesChange = (event: any, index: any, value: any) => {
    this.setState({ currentPrerequisite: value })
  }

  private onPrerequisitesTypeChange = (event: any, index: any, value: any) => {
    this.setState({ currentPrerequisiteType: value })
  }

  public render(): JSX.Element {
    const periods = toJS(this.injected.baselineStore.periods)
    
    const canOptimise = this.injected.permissionStore.canOptimise()

    let selectedProject = this.injected.scenariosStore.selectedProject
    const constraints = this.injected.scenariosStore.baseline.constraints
    if (!(selectedProject === undefined)) {
      // TODO this is hack method to get all constraints and reference stupid column1, column2, etc
      // const constraints = this.injected.scenariosStore.constraints
      const duration = (selectedProject && selectedProject.duration) || 1
      const attributeList = selectedProject.attributes
      const isMandatory =
        selectedProject && selectedProject.mandatory === Mandatory.Mandatory
      const excluded =
        selectedProject && selectedProject.mandatory === Mandatory.Excluded
      // const prerequisitesOptions = this.injected.scenariosStore.working.projects.filter(
      //   (x: { mandatory: any; code: any }) =>
      //     x.mandatory !== Mandatory.Excluded && x.code !== selectedProject.code
      // )
      // const prerequisites = selectedProject.prerequisites
      const StrategicIndex = selectedProject.strategicIndex
      const currentStatus = selectedProject.status
      const statusList = nextPossibleProjectState(selectedProject)
      // add current status so it shows correctly
      statusList.push(currentStatus)
    

      // const menuItems = (values: { indexOf: (arg0: any) => number }) => {
      //   return prerequisitesOptions.map(
      //     (project: { code: React.ReactNode; name: React.ReactNode }) => (
      //       <MenuItem
      //         key={`prerequisites-${project.code}`}
      //         insetChildren={true}
      //         checked={values && values.indexOf(project.code) > -1}
      //         value={project.code}
      //         primaryText={project.code}
      //         secondaryText={project.name}
      //       />
      //     )
      //   )
      // }
      if (this.state.optimising) {
        return (
          <div
            style={{
              flex: "0 0 65vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )
      }
      const startPeriodsActions = [
        <FlatButton
          key="startPeriodsCancel"
          label="Cancel"
          primary={true}
          onClick={this.startPeriodClose}
        />,
        <FlatButton
          key="startPeriodsAdd"
          label="Add"
          primary={true}
          onClick={this.startPeriodAdd}
        />,
      ]
      const prerequisitesActions = [
        <FlatButton
          key="prerequisiteCancel"
          label="Cancel"
          primary={true}
          onClick={this.prerequisiteClose}
        />,
        <FlatButton
          key="prerequisiteAdd"
          label="Add"
          primary={true}
          onClick={this.prerequisiteAdd}
        />,
      ]
      const DurationConfirmActions= [
        <FlatButton
          key="durationCancel"
          label="Cancel"
          primary={true}
          onClick={this.durationCancel}
        />,
        <FlatButton
          key="durationConfirm"
          label="Accept"
          primary={true}
          onClick={this.durationConfirm}
        />,
      ]
      const statusErrorActions= [
        <FlatButton
          key="StatusCancel"
          label="Cancel"
          primary={true}
          onClick={this.statusConfirmCancel}
        />,
        <FlatButton
          key="StatusConfirm"
          label="Change Anyway"
          primary={true}
          onClick={this.statusConfirmAccept}
        />,
      ]

      return (
        <div
          style={{
            flex: "0 0 65vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div style={styles.row}>
            <Checkbox
              label="Mandatory"
              checked={isMandatory}
              disabled={excluded}
              onCheck={this.updateMandatory}
              style={styles.checkbox}
            />
            {/*
            <Checkbox
              label="Excluded"
              checked={excluded}
              onCheck={this.updateExcluded}
              style={styles.checkbox}
              disabled={isMandatory}
            /> */}
            <SelectField
              floatingLabelText="Status"
              value={currentStatus}
              onChange={this.onStatusChange}
              autoWidth={true}
            >
              {statusList.map((stat: status, index: number) => (
                <MenuItem
                  key={index}
                  value={toNumber(stat)}
                  primaryText={projectStatusToString(stat)}
                />
              ))}

            </SelectField>
          </div>
          <div style={styles.row}>Strategic Index: {StrategicIndex}</div>
          <div style={styles.formRow}>
            <SelectField
              floatingLabelText="Duration"
              value={duration}
              onChange={this.onDurationChange}
              autoWidth={true}
            >
              {periods.map((item: any, index: number) => (
                <MenuItem
                  key={index}
                  value={index + 1}
                  primaryText={index + 1}
                />
              ))}
            </SelectField>
            <TextField
              id={"Benefit"}
              floatingLabelText={selectedProject.benefits.name} //TODO change to benefit string
              value={selectedProject.benefits.values[0]}
              onChange={this.onBenefitChange}
            />
          </div>
          {/* Constraints */}
          <div>
            {constraints.map((constraint, constraintIndex) => {
              let total = 0
              return (
                <div key={constraint.name + "_table"}>
                  <div style={styles.row}> {constraint.name}</div>
                  <Table>
                    <TableHeader
                      adjustForCheckbox={false}
                      displaySelectAll={false}
                    >
                      <TableRow>
                        {new Array(selectedProject.duration)
                          .fill(0)
                          .map((d, index) => {
                            return (
                              <TableHeaderColumn
                                style={styles.tableColumn}
                                key={constraint.name + "_Period_" + (index + 1)}
                              >
                                Period {index + 1}
                              </TableHeaderColumn>
                            )
                          })}
                        <TableHeaderColumn style={styles.tableColumn}>
                          Total
                        </TableHeaderColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        {selectedProject.attributes[constraintIndex].values.map((value: number, subindex: number) => {
                          const onChange = (event: any) => {
                            this.constraintChange(constraint.name, subindex, event)
                          }
                          total += value
                          return (
                            <TableRowColumn
                              key={constraint.name + subindex}
                              style={styles.tableColumn}
                            >
                              <TextField
                                id={attribute.name + ":" + subindex}
                                value={value}
                                onChange={onChange}
                              />
                            </TableRowColumn>
                          )

                        }
                        )
                        }

                        <TableRowColumn
                          key={constraint.name + ".total"}
                          style={styles.tableColumn}
                        >
                          {total}
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              )
            })}
          </div>
          <div style={styles.titleStyle}>Start Periods</div>
          <div style={styles.wrapper}>
            {this.injected.scenariosStore.selectedProject.startPeriods.map(
              (id: string | number | undefined, index: number) => {
                const removeStartPeriodfn = () => this.removeStartPeriod(index)
                return (
                  <Chip key={id} onRequestDelete={removeStartPeriodfn}>
                    {
                      this.injected.baselineStore.periods.find(
                        (p: any) => p.index === id
                      ).name
                    }
                  </Chip>
                )
              }
            )}
            <Chip onClick={this.openStartPeriod}>
              <Avatar icon={<Add />} />
              Add New
            </Chip>
          </div>
          <div style={styles.titleStyle}>Capabilities</div>
          <div style={styles.wrapper}>
            {this.injected.scenariosStore.selectedProject.capabilities.map(
              (cap: { id: string; contribution: number }) => {
                return (
                  <Chip key={cap.id}>
                    {this.injected.baselineStore.getCapabilityFromCode(cap.id)
                      .name +
                      " : " +
                      cap.contribution}
                  </Chip>
                )
              }
            )}
          </div>
          <div style={styles.titleStyle}>Prerequisites</div>
          <div style={styles.wrapper}>
            {this.injected.scenariosStore.selectedProject.prerequisites.map(
              (id: any, index: number) => {
                const removePreRequisitefn = () =>
                  this.removePreRequisite(index)
                return (
                  <Chip
                    key={id + "" + index}
                    onRequestDelete={removePreRequisitefn}
                  >
                    {id.name} : {id.type}
                  </Chip>
                )
              }
            )}
            <Chip onClick={this.openPreRequisite}>
              <Avatar icon={<Add />} />
              Add New
            </Chip>
          </div>

          <FlatButton
            primary={true}
            onClick={this.optimiseScenario}
            label="Optimise"
            disabled={!canOptimise}
          />
          {/** Add Start Period Popup */}
          <Dialog
            open={this.state.showAddStartPeriod}
            title={"Select Start Period"}
            actions={startPeriodsActions}
          >
            <SelectField
              floatingLabelText="Periods"
              onChange={this.onStartPeriodChange}
              value={this.state.currentStartPeriod}
              style={{ width: "100%" }}
              maxHeight={200}
            >
              {this.injected.baselineStore.periods.map(
                (
                  period: { index: any; name: React.ReactNode },
                  periodIndex: string | number | undefined
                ) => {
                  return (
                    <MenuItem
                      key={periodIndex}
                      value={period.index}
                      primaryText={period.name}
                    />
                  )
                }
              )}
            </SelectField>
          </Dialog>        
          {/** Select preRequisites popup*/}
          <Dialog
            open={this.state.showPreRequisite}
            title={"Select PreRequisites"}
            actions={prerequisitesActions}
          >
            <SelectField
              floatingLabelText="Project"
              onChange={this.onPrerequisitesChange}
              value={this.state.currentPrerequisite}
              style={{ width: "100%" }}
            >
              {this.injected.scenariosStore.working.projects.map(
                (project, projectIndex) => {
                  return (
                    <MenuItem
                      key={projectIndex}
                      value={project.code}
                      primaryText={project.code + " : " + project.name}
                    />
                  )
                }
              )}
            </SelectField>
            <SelectField
              floatingLabelText="Pre-Requisite Type"
              onChange={this.onPrerequisitesTypeChange}
              value={this.state.currentPrerequisiteType}
              style={{ width: "100%" }}
            >
              {Object.keys(Prerequisites).map((key) => {
                return (
                  <MenuItem
                    key={key}
                    value={key.toString()}
                    primaryText={key}
                  />
                )
              })}
            </SelectField>
          </Dialog>
          {/** Confirm Duration popup*/}
            <Dialog
            open={this.state.showDurationConfirm}
            title={"Warning: Duration Change"}
            actions={DurationConfirmActions}
          >
            This will change the projects status to { projectStatusToString(this.state.newStatus)}

          </Dialog>
           {/** Confirm statusError popup*/}
           <Dialog
            open={this.state.showStatusError}
            title={"Error in assigning new status"}
            actions={statusErrorActions}
          >
            Warning, {projectStatusToString(this.state.newStatus)} state is incompatible with the currently scheduled start Period.          </Dialog>
        </div>
      )
    } else {
      return <div />
    }
  }
}

const styles: any = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
  formRow: {
    flex: "0 0 auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  titleStyle: { fontSize: "100%", paddingBottom: "10px" },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  row: {
    alignItems: "center",
    paddingTop: "5px",
    paddingBottom: "2px",
    display: "flex",
    flexDirection: "row",
    overflow: "visible",
  },
  tableColumn: {
    width: 100,
  },
}
