import ScenariosStore from "../mobx/ScenariosStore"
import { ProjectProps } from "../mobx/Project"
import attribute from "../mobx/Attribute"

/**
 * This file is for saving to the database in one central location, its build off the mobx store for data input
 *
 */

/**
 * saves the given config to the mySql database
 * @param object the config
 * @param token authID authorization Token
 */
export const saveConfig = (
  object: { id: number; companyID: string; config: string },
  token: string
): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/config`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(object)
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  )
}

/**
 * saves the given scenario to the database
 * @param scenario the scenario to be saved
 * @param token authID authorization Token
 */
export const saveScenario = (
  scenario: ScenariosStore,
  token: string
): Promise<any> => {
  console.log(scenario)
  let isNew = scenario.id < 0
  console.log(isNew)
  let mappedScenario: any
  mappedScenario = {
    baselineId: scenario.baselineId,
    name: scenario.working.name,
    description: scenario.working.description,
    constraints: JSON.stringify(scenario.optimised.constraints),
    projects: scenario.optimised.projects.map(p => mapProjectToDB(p)),
    currentPeriodIndex: scenario.optimised.currentPeriod
  }
  if (!isNew) {
    mappedScenario.id = scenario.id
  }

  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/scenario`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(mappedScenario)
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  })
}

// export const mapScenarioToDB = (scenario: any) => {
//     const mappedScenario = {
//       baselineId: scenario.baselineId,
//       name: scenario.customname,
//       description: scenario.description,
//       constraints: JSON.stringify(scenario.constraint_data),
//       projects: scenario.dot_data.map(p => mapProjectToDB(p))
//     }
//     return mappedScenario
//   }

const mapProjectToDB = (project: ProjectProps) => {
  const newproject = {
    ...project,
    // TODO -- update this once db is updated
    prerequisites: JSON.stringify(project.prerequisites),
    capabilities: JSON.stringify(project.capabilities),
    startPeriod: project.startPeriod,
    startPeriods: JSON.stringify(project.startPeriods),
    alive: project.alive === true,
    scenarioId: project.scenarioId,
    benefits: JSON.stringify(project.benefits.values[0]),
    benefitString: project.benefits.name,
    inFlight: project.inFlight === true
  }


  //group attributes -- assumes attributes are sorted in ascending period order
  // back end expects 1 indexed lists, +1 to period
  let newAttibutes = Array()
  project.attributes.forEach(attribute => {
    newAttibutes.push({ name: attribute.name, value: attribute.values[0], periodStart: 1, periodEnd: 1 })
    // loop though each period grouping as we go
    attribute.values.forEach((periodValue: number, index) => { // should make this a function
      let last = newAttibutes.length - 1
      if (newAttibutes[last].value === periodValue) {
        newAttibutes[last].periodEnd = index + 1
      } else {
        newAttibutes.push({ name: attribute.name, value: periodValue, periodStart: index + 1, periodEnd: index + 1 })
      }
    })
  })
  newAttibutes.push({ name: project.benefits.name, value: project.benefits.values[0], periodStart: 1, periodEnd: 1 })
  // just tag benefits on the end so it all is saved in the same place
  project.benefits.values.forEach((periodValue: number, index) => {
    let last = newAttibutes.length - 1
    if (newAttibutes[last].value === periodValue) {
      newAttibutes[last].periodEnd = index + 1
      if (index === 98) { // this is hard coded, should be set to time periods +1
        newAttibutes[last].periodEnd = 0
        newAttibutes[last].periodStart = 0
      }
    } else {
      newAttibutes.push({ name: attribute.name, value: periodValue, periodStart: index + 1, periodEnd: index + 1 })

    }
  })
  newproject.attributes = newAttibutes
  console.log(newproject)

  return newproject
}

/**
 * Not currently used
 * 
 * saves the given project to the database
 * @param project the project to be saved
 * @param token authID authorization Token
 */
export const saveProject = (
  project: ProjectProps,
  token: string
): Promise<any> => {
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/project`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(project)
    })
      .then(async result => {
        resolve(result.json())
      })
      .catch(reject)
  )
}

export const saveLogo = (
  logo: any,
  token: string
): Promise<any> =>{
  return new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API_URL}/logo`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: logo

    })
    .then(async result => {
      resolve(result.json)
    }).catch(reject)
  )

}

