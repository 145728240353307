import React from 'react';
import { Link } from 'react-router-dom';

import RaisedButton from 'material-ui/RaisedButton';

import styles from './styles';

export class Home extends React.Component {
  public render() {
    return (
      <div style={styles.root}>
        <div style={styles.banner}>
        <img
            src={'/logos/acuitas.svg'}
            style={styles.bannerImage}
          />
        <Link style={styles.button} to={`/dashboard`}>
          <RaisedButton label="Open Dashboard" style={styles.button} primary/>
        </Link>
        </div>
      </div>
    );
  }
}

export default Home;
