import { trimEnd } from "lodash";
import { project } from "ramda";
import attribute from "../mobx/Attribute";
import { Project } from "../mobx/Project"
import Scenario, { ScenarioProps } from "../mobx/Scenario";

/**
 * 
 * @param project1 
 * @param project2 
 * 
 * returns true if the comparison has a difference
 */
export function compareProject (project1: Project, project2: Project){
    
    let comparison:any ={ attributes:[]}
    let diff = false
    //things to compare
    //status
    if(project1.status !== project2.status){
        comparison.status = true
        diff = true
    }
    //alive
    if (project1.alive !== project2.alive){
        comparison.alive = true
        diff = true
    }
    //constraint/ attribute values values
    project1.attributes.forEach((attribute1:any)=> {
        let name = false
        let sameValue = false
        let index = project2.attributes.findIndex(
            (attr: attribute) => attr.name === attribute1.name)
        if(index == -1){
            name = true
        }else{
            let total1 = attribute1.total()
            let total2 = project2.attributes[index].total()
            if(total1 != total2){
                sameValue = true
            }
            if(attribute1.values.toString() !== project2.attributes[index].values.toString()){
                sameValue = true
            }
        }
        if(name === true || sameValue === true){
            comparison.attributes.push({ID:attribute1.name, name:name, value : sameValue, })
            diff = true
        }
    });
    //benefit
    if(project1.benefits.total() !== project2.benefits.total()){
        comparison.benefit = true
    }
    if(project1.benefits.values.toString() !== project1.benefits.values.toString()){
        comparison.benefit = true
    }
    //prerequisites -- check for actual name and then type
    if(project1.prerequisites.toString() !== project2.prerequisites.toString()){
        comparison.prerequisites = true
    }

    //duration
    if( project1.duration !== project2.duration){
        comparison.duration = true
        diff = true
    }
    //mandatory
    if( project1.mandatory !== project2.mandatory){
        comparison.mandatory = true
        diff = true
    }
    //startPeriod
    if(project1.startPeriod !== project2.startPeriod){
        comparison.startPeriod = true
        diff = true
    }
    //startPeriods
    if(project1.startPeriods.sort().toString() != project2.startPeriods.sort().toString() ){
        comparison.startPeriods = true
        diff = true
    }
    if(diff == true){
        return comparison}
    else{
        return undefined
    }
}

/**
 * 
 * @param projects 
 */
export function compareProjects (projects: Project[]){
    
}

/**
 * 
 * @param scenario1 
 * @param scenario2 
 */
export function compareScenario (scenario1: ScenarioProps, scenario2: ScenarioProps) {

    //constraints
    let comparison: any = {constraints:[], projects:[]}
        for (let c = 0; c < scenario1.constraints.length; c++) {
            let value = false;
            let valueCoversRange = false
            if(scenario1.constraints[c].value !== scenario2.constraints[c].value){
                value = true
            }
            if(scenario1.constraints[c].valueCoversRange !== scenario2.constraints[c].valueCoversRange){
                valueCoversRange = true
            }
            if(value || valueCoversRange){
                comparison.constraints.push({name:scenario1.constraints[c].name, value, valueCoversRange})
            }        
        }
    //current periods
    if(scenario1.currentPeriod !== scenario2.currentPeriod){
        comparison.currentPeriod = true
    }
    // Projects
    // let scenario1Unique = scenario1.projects.filter(p=>  !scenario2.projects.some(b => b.code == p.code))
    // console.log(scenario1Unique)
    let scenario2Unique = scenario2.projects.filter(p=> !scenario1.projects.some(b => b.code == p.code))
    for(let p = 0; p < scenario1.projects.length; p++){
        let index2 = scenario2.projects.findIndex((proj:any) => proj.code === scenario1.projects[p].code)
        let project : any= {}
        if(index2 != -1){
            project = compareProject(scenario1.projects[p], scenario2.projects[index2])
        }else{
            project = {
                status : true,
                mandatory : true,
                alive : true,
                duration : true,
                startPeriod : true,
                startPeriods : true,
                preRequisites : true,
                attributes: []
            }
            scenario1.projects[p].attributes.forEach(attr => {
                project.attributes.push(
                    {ID:attr.name, name:true, value : true, }
                )
            });

        }
        if(project != {} && project != undefined){
            project.code = scenario1.projects[p].code
            comparison.projects.push(project)
        }
    }
    // add scenario 2 uniques
    for(let p = 0; p < scenario2Unique.length; p++){
        let project : any= {}
        
            project = {
                code: scenario2Unique[p].code,
                status : true,
                mandatory : true,
                alive : true,
                duration : true,
                startPeriod : true,
                startPeriods : true,
                preRequisites : true,
                attributes: []
            }
            scenario2Unique[p].attributes.forEach(attr => {
                project.attributes.push(
                    {ID:attr.name, name:true, value : true, }
                )
            });
        comparison.projects.push(project)
    }
    
 return comparison
}