import React from "react"
import { observer, inject } from "mobx-react"

import { List, ListItem } from "material-ui/List"
import UiStore, { DashboardSection } from "../../mobx/UiStore"

import DataImport from "../DataImport"
import Scenarios from "../Scenarios"
import { BaselineSelection } from "../../components/dashBoard/BaselineSelection"
import { Settings } from "../../components/dashBoard/Settings"

import styles from "./styles"
import { Comparison } from "../../components/dashBoard/Comparison"
import PermissionStore from "../../mobx/PermissionStore"

interface Props {}
interface InjectedProps extends Props {
  uiStore: UiStore,
  permissionStore: PermissionStore
}
interface MenuOption {
  label: string
  section: DashboardSection
}

const menuOptions: MenuOption[] = [
  { label: "Scenarios", section: "scenarios" },
  // { label: "Change Current Baseline", section: "changebaseline" },
  { label: "Settings", section: "settings" },
  {label: "Analysis Scenarios", section: "comparison"}
]

const adminOptions: MenuOption[] = [
  { label: "Data Import", section: "dataimport" },

]
@inject(({ store }) => {
  return {
    configStore: store.configStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    permissionStore: store.permissionStore
  }
})
@observer
export class Dashboard extends React.Component {
  private get injected() {
    return this.props as InjectedProps
  }
  
  private onListItemClick = (section: DashboardSection) => {
    this.injected.uiStore.setDashboardSectionSelected(section)
  }
  public render() {
    const { permissionStore } = this.injected
    let userOptions =  menuOptions;
    if (permissionStore.showDataImportView) {
      userOptions =  menuOptions.concat(adminOptions)
    }
    const { uiStore } = this.injected
    const { dashboardSectionSelected } = uiStore
    return (
      <div style={styles.root}>
        <div style={styles.leftWrapper}>
          <List>
            {userOptions.map((item: MenuOption) => (
              <DashboardMenuListItem
                key={item.section}
                {...item}
                selectedOption={dashboardSectionSelected}
                onListItemClick={this.onListItemClick}
              />
            ))}
          </List>
        </div>
        <div style={styles.rightWrapper}>
          {dashboardSectionSelected === "scenarios" && <Scenarios />}
          {dashboardSectionSelected === "dataimport" && <DataImport />}
          {dashboardSectionSelected === "changebaseline" && (
            <BaselineSelection />
          )}
          {dashboardSectionSelected === "settings" && <Settings />}
          {dashboardSectionSelected === "comparison" && <Comparison />}
        </div>
      </div>
    )
  }
}

interface DashboardMenuListItemProps extends MenuOption {
  selectedOption: DashboardSection
  onListItemClick(section: DashboardSection): void
}

const DashboardMenuListItem = React.memo(
  ({
    label,
    section,
    selectedOption,
    onListItemClick
  }: DashboardMenuListItemProps) => {
    const onListItemClickFn = () => {
      onListItemClick(section)
    }
    const style =
      selectedOption === section
        ? styles.reportMenuItemSelected
        : styles.reportMenuItem
    return (
      <ListItem primaryText={label} style={style} onClick={onListItemClickFn} />
    )
  }
)

export default Dashboard
