/**
 * this file is meant as a extra layer on top of getDATA
 * to make sure that the multiple places that need to load
 * scenarios and baselines all access the data in a similar way
 */

import { formatProjects, ScenarioProps } from "../mobx/Scenario";
import {BaselineProps, mapCapabilities} from "../mobx/BaselineStore"
import { getCapabilities, getGlobalData, getLogo, getMaturityLevels, getScenario } from "./getData";
import * as R from "ramda";

/**
 * loads scenario from the db and formats it into scenarioProps
 * @param scenarioId the scenario ID to load
 * @param token the access token to use in get calls
 */
export const loadAndFormatScenario = async(scenarioId: any, token:any) =>{
      const scenarioData = await getScenario(token, scenarioId)
  
      // TODO update mapping of scenarioStore.setScenario
      const newScenario: ScenarioProps = {
        id: scenarioData.id, // TODO why is this crashing
        settings: JSON.parse(scenarioData.Baseline.capabilityMap),
        constraints: JSON.parse(scenarioData.constraints),
        projects: scenarioData.Projects,
        name: scenarioData.name,
        description: scenarioData.description,
        selectedProjectCode: undefined,
        highlightedProjectCode: undefined,
        baselineId: scenarioData.BaselineId,
        isBaseline: scenarioData.isBaseline,
        currentPeriod: scenarioData.currentPeriod ? 0 : scenarioData.currentPeriodIndex
      }
      newScenario.projects = formatProjects(scenarioData)
      newScenario.constraints.map((constraint: any, index: number) => {
        constraint.id = index + 1
        constraint.valueCoversRange =
          constraint.valueCoversRange === "true" ||
          constraint.valueCoversRange === true
      })
      return newScenario
}

/**
 * loads baseline from the db and formats in into a data structure matching baselineStore
 * @param token the access token to use in get calls
 */
export const loadAndFormatBaseline = async(token:any) =>{
  
  const globalData = await getGlobalData(token)
    
  const capabilitiesResponse = await getCapabilities(token)
  const maturityMapResponse = await getMaturityLevels(token)
  

  const capabilityMap = mapCapabilities(capabilitiesResponse.capabilities)
  const newBaseline: BaselineProps = {
    initiatives: R.flatten(
      globalData.projectStrategies.map((strategy: any) => {
        const stratInitiatives = strategy.ProjectInitiatives.map(
          (initiative: any) => {
            const mappedInitiative = {
              id: initiative.id,
              code: initiative.code,
              name: initiative.name,
              StrategyId: initiative.ProjectStrategyId
            }
            return mappedInitiative
          }
        )

        return stratInitiatives
      })
    ),
    capabilityMap,
    //changed this look at more
    maturityMap: maturityMapResponse.maturityLevels,
    strategies: globalData.projectStrategies,
    types: globalData.projectType,
    constraints: JSON.parse(globalData.constraints),
    benefits: globalData.benefits,
    currentBaseline: globalData.BaselineId,
    loaded: true,
    selectedConstraint: null,
    capabilities: capabilitiesResponse.capabilities,
    capabilityMultipliers: [],
    periods: globalData.periodSettings,
    goals: globalData.goals
  }
  return newBaseline
}

export const loadLogo = async(logo:string,token:any) =>{
  if(logo == ''){
    return ''
  }
  const currentLogo = logo
    let imageBlob = await getLogo(token,currentLogo)
    //setup file properties
    imageBlob.name = currentLogo
    imageBlob.lastModifiedDate = new Date()
    //get file type
    let split  = currentLogo.split('.')
    let type = "image/" + split[split.length-1]
    if(type == "image/svg"){
      type = "image/svg+xml"
    }

    let imageFile= new File([imageBlob], currentLogo, {type: type})
    return imageFile
}