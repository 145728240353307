import * as React from "react"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"

import RightSideBar from "../components/RightSideComponents/RightSideBar"
import ProjectType from "../components/RightSideComponents/ProjectType"
import GanttChart from "../components/GanttChart"

interface Props {}

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}

@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
  }
})
@observer
export class GanttView extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps
  }
  private setSelectedProject = (id: string | undefined) => {
    this.injected.scenariosStore.setHighlightedProject(id)
  }

  private openProjectDialog = () => {
    const { scenariosStore, uiStore } = this.injected
    if (scenariosStore.highlightedProjectCode !== undefined) {
      uiStore.showProjectPropertiesPopup = true
      scenariosStore.setSelectedProject(scenariosStore.highlightedProjectCode)
    }
  }

  private clearSelection = () => {
    this.injected.scenariosStore.setHighlightedProject(undefined)
    this.injected.uiStore.hideHoverbox()
  }
  private getDifference = () => {
    const difference = this.injected.scenariosStore.getScenarioDifference()
    return difference
  }

  public render(): JSX.Element {
    const { scenariosStore, baselineStore } = this.injected
    const optimisedObjectNew = toJS(scenariosStore.optimised)
    const projectArrayNew = optimisedObjectNew.projects

    const difference = this.getDifference()
    const currentPeriod = scenariosStore.working.currentPeriod
    const OptimisedPeriod = scenariosStore.optimised.currentPeriod
    const periodSettings = toJS(baselineStore.periods)
    const typeColors = toJS(baselineStore.types)
    const displayProjectCode = toJS(scenariosStore.displayProjectCode)

    return (
      <div style={styles.rightContent}>
        <div style={styles.ganttWrapper}>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <div>
              <GanttChart
                difference={difference}
                displayProject={displayProjectCode}
                openProjectDialog={this.openProjectDialog}
                setSelectedProject={this.setSelectedProject}
                projectArray={projectArrayNew}
                periodSettings={periodSettings}
                currentPeriod = {currentPeriod}
                oldPeriod = {OptimisedPeriod}
                typeColors={typeColors}
              />
            </div>
          </div>
          <div
            style={{
              height: "120px",
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <ProjectType types={typeColors} />
          </div>
        </div>
        <RightSideBar
          selectedProject={displayProjectCode}
          setSelectedProject={this.setSelectedProject}
          clearSelection={this.clearSelection}
        />
      </div>
    )
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  rightContent: {
    flex: "1",
    display: "flex",
    background: "rgb(236, 236, 236)",
  },
  ganttWrapper: {
    width: "50%",
    position: "relative",
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}
