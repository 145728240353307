import * as React from "react"
import { toJS } from "mobx"
import { observer, inject } from "mobx-react"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"

import RightSideBar from "../components/RightSideComponents/RightSideBar"
import RadarGraph from "../components/RadarGraph"
import RadarOuterCircle from "../components/RadarOuterCircle"

import ProjectType from "../components/RightSideComponents/ProjectType"
import CostLegend from "../components/RightSideComponents/CostLegend"

interface Props {}

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}
@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
  }
})
@observer
export class RadarView extends React.Component<Props, {}> {
  private get injected() {
    return this.props as InjectedProps
  }

  private openProjectDialog = () => {
    const { scenariosStore, uiStore } = this.injected
    if (scenariosStore.highlightedProjectCode !== undefined) {
      if (scenariosStore.selectedProjectCode) {
        uiStore.showProjectPropertiesPopup = true
      } else {
        scenariosStore.setSelectedProject(scenariosStore.highlightedProjectCode)
      }
    }
  }
  private clearSelection = () => {
    this.injected.scenariosStore.setHighlightedProject(undefined)
    this.injected.uiStore.hideHoverbox()
  }

  private setSelectedProject = (id: string | undefined) => {
    this.injected.scenariosStore.setHighlightedProject(id)
  }

  private getDifference = () => {
    const difference = this.injected.scenariosStore.getScenarioDifference()
    return difference
  }

  public render(): JSX.Element {
    const {
      scenariosStore,
      uiStore,
      baselineStore,
      configStore,
    } = this.injected
    const { showHoverbox, hideHoverbox, changeHoverboxPosition } = uiStore
    const optimisedObject = toJS(scenariosStore.optimised, {
      exportMapsAsObjects: true,
    })

    const projectArray = toJS(optimisedObject.projects, {
      exportMapsAsObjects: true,
    })
    const difference = this.getDifference()

    const getSizeFrom = uiStore.RadarGraphGetSizeFrom
    const typeArray = baselineStore.types
    const strategyArray = toJS(baselineStore.strategies, {
      exportMapsAsObjects: true,
    })
    const selectedProjectCode = toJS(scenariosStore.selectedProjectCode)
    const displayProjectCode = toJS(scenariosStore.displayProjectCode, {
      exportMapsAsObjects: true,
    })
    const selectedProject = toJS(scenariosStore.selectedProject)
    const displayProject = toJS(scenariosStore.displayProject)

    const capabilityMap = toJS(baselineStore.capabilities)
    const periodArray = toJS(baselineStore.periods, {
      exportMapsAsObjects: true,
    })
    const currentPeriod= toJS(scenariosStore.working.currentPeriod)
    const optimisedPeriod = toJS(scenariosStore.optimised.currentPeriod)

    const benefits = toJS(baselineStore.benfits)
    const projectInitiatives = toJS(baselineStore.initiatives)
    // ------------------------------------------------------------------------------
    // TODO -- UPDATE THESE TWO -- ATM not sure where they come from in database (pretty sure objectives is empty)
    // ------------------------------------------------------------------------------
    const goals = toJS(baselineStore.goals)
    const objectives: any[] = []
    return (
      <div style={styles.rightContent}>
        <div
          style={{
            width: "50%",
            position: "relative",
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div>
              <RadarGraph
                Catagories={this.injected.configStore.projectCircleScale}
                typeColors={typeArray}
                difference={difference}
                strategyArray={strategyArray}
                projectArray={projectArray}
                getSizeFrom={getSizeFrom}
                selectedProject={selectedProjectCode}
                highlightedProject={displayProjectCode}
                setSelectedProject={this.setSelectedProject}
                openProjectDialog={this.openProjectDialog}
                clearSelection={this.clearSelection}
                showHoverbox={showHoverbox}
                hideHoverbox={hideHoverbox}
                changeHoverboxPosition={changeHoverboxPosition}
                periodArray={periodArray}
                currentPeriod= {currentPeriod}
                oldPeriod = {optimisedPeriod}
              />
              <RadarOuterCircle
                selectedDotClicked={selectedProject}
                selectedDot={displayProject}
                benefits={benefits}
                projectInitiatives={projectInitiatives}
                goals={goals}
                objectives={objectives}
                capabilityMap={capabilityMap}
                showHoverbox={showHoverbox}
                hideHoverbox={hideHoverbox}
                changeHoverboxPosition={changeHoverboxPosition}
              />
            </div>
          </div>
          <div
            style={{
              height: "120px",
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <ProjectType types={typeArray} />

            <CostLegend
              Catagories={configStore.projectCircleScale}
              getSizeFrom={getSizeFrom}
            />
          </div>

          <div />
        </div>
        <RightSideBar
          selectedProject={displayProjectCode}
          setSelectedProject={this.setSelectedProject}
          clearSelection={this.clearSelection}
        />
      </div>
    )
  }
}

const styles: { [index: string]: React.CSSProperties } = {
  rightContent: {
    flex: "1",
    display: "flex",
    background: "rgb(236, 236, 236)",
  },
}
