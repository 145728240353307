import * as React from "react"
import * as accounting from "accounting"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

//@ts-ignore
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent"

import TextField from "material-ui/TextField"
import FlatButton from "material-ui/FlatButton"
import CircularProgress from "material-ui/CircularProgress"
import Toggle from "material-ui/Toggle"

import { projectAttributeTotal } from "../mobx/Scenario"
import { Project } from "../mobx/Project"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"

import { compareToProjects, CompareDiffrence } from "../utils/SolutionUtils"
import CustomLineChart from "../components/CustomLineCharts"
import { ScenarioToOptimise, getSolutions } from "../utils/ProjectSolution"
import numberFormat from "../utils/NumberBeautify"
import ConfigStore from "../mobx/ConfigStore"

export interface State {
  chartData: any[]
  loading: boolean
  loaded: boolean
  graphWidth: number
  graphHeight: number
  efMin: number
  efMax: number
  efSteps: number
  xAxisLabel: string
  YAxisLabel: string
  ticks: number[]
  domain: number[]
  compareToBase: boolean
}
const CustomToolTip = (props: any, compareToBase: boolean) => {
  if (props.payload[0] != null) {
    const newPayload = [
      ...props.payload,
      {
        name: "Expediture",
        value:
          "$" +
          numberFormat(props.payload[0].payload.x, 2) +
          " / " +
          numberFormat(props.payload[0].payload.maxX, 2),
        color: "blue"
      }
    ]

    let additions = ""
    let removals = ""
    if (compareToBase) {
      if (props.payload[0].payload.diffBase.length > 0) {
        props.payload[0].payload.diffBase.forEach(
          (diff: { alive: boolean; code: string }) => {
            if (diff.alive === true) {
              additions += diff.code + " , "
            } else {
              removals += diff.code + " , "
            }
          }
        )
      }
    } else {
      if (props.payload[0].payload.diffLast.length > 0) {
        props.payload[0].payload.diffLast.forEach(
          (diff: { alive: boolean; code: string }) => {
            if (diff.alive === true) {
              additions += diff.code + " , "
            } else {
              removals += diff.code + " , "
            }
          }
        )
      }
    }
    if (additions.length > 0) {
      newPayload.push({
        name: "Added",
        value: additions,
        color: "#4CAF50"
      })
    }
    if (removals.length > 0) {
      newPayload.push({
        name: "Removed",
        value: removals,
        color: "#F44336"
      })
    }

    return (
      <DefaultTooltipContent {...props} label={null} payload={newPayload} />
    )
  }
  return <DefaultTooltipContent {...props} />
}
export interface Props { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}
@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore,
    configStore: store.configStore
  }
})
@observer
export class EfficientFrontierTab extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      ticks: [],
      graphHeight: 45,
      graphWidth: 45,
      chartData: [],
      loaded: false,
      loading: false,
      efMin: 0,
      efMax: 0,
      efSteps: 5,
      domain: [0, 5],
      YAxisLabel: "Benefit",
      xAxisLabel: "Expediture",
      compareToBase: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  private handleChange(event: any) {
    let value = parseInt(event.target.value, 10)
    if (isNaN(value)) {
      value = 0
    }
    switch (event.target.name) {
      case "minAmount": {
        const unformattedValue = accounting.unformat(event.target.value)
        this.setState({
          efMin: unformattedValue
        })
        break
      }
      case "maxAmount": {
        const unformattedValue = accounting.unformat(event.target.value)
        this.setState({
          efMax: unformattedValue
        })
        break
      }
      case "steps": {
        this.setState({
          efSteps: value
        })
        break
      }
      default:
        return
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    if (this.injected.scenariosStore.working.constraints) {
      const constraint = this.injected.scenariosStore.working.constraints.find(
        checkConstraint => checkConstraint.name === "Expenditure"
      )
      if (constraint !== undefined) {
        this.setState({ efMin: 0, efMax: constraint.value })
      } else {
        console.error("Incorrect Costraint ID")
      }
    }
  }

  public componentWillUnMount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  private updateDimensions = () => {
    // magic numbers
    const barDiv = document.getElementById("barDiv")
    const chartDiv = document.getElementById("chartDiv")
    if (barDiv && chartDiv) {
      const height = 600 - barDiv.offsetHeight
      const width = chartDiv.offsetWidth
      if (height > 0 && width > 0) {
        this.setState({
          graphHeight: height,
          graphWidth: width
        })
      }
    }
  }

  private calculateEfficientFrontier = async () => {
    this.setState({
      loading: true,
      loaded: false
    })
    const authToken = this.injected.permissionStore.getToken()
    const canOptimise = this.injected.permissionStore.canOptimise()
    const forceRefresh = this.injected.configStore.forceRefresh
    if(!canOptimise){
      return
    }
    let Cur = this.state.efMin
    const stepIncrease =
      (this.state.efMax - this.state.efMin) / this.state.efSteps
    let solutionArray: any[] = []
    const index = this.injected.scenariosStore.working.constraints.findIndex(
      constraint => constraint.name === "Expenditure"
    )

    for (let step = 0; step < this.state.efSteps + 1; step++) {
      const newScenario = toJS(this.injected.scenariosStore.working)
      const periods = this.injected.baselineStore.periods.length
      newScenario.constraints[index].value = Cur
      const solutionArrayItem = await ScenarioToOptimise(
        newScenario,
        authToken,
        periods,
        forceRefresh
      )
      solutionArray.push(solutionArrayItem)
      Cur += stepIncrease
    }

    solutionArray = await getSolutions(solutionArray, authToken)

    solutionArray = solutionArray.map(item => {
      const solution = item.status === "error" ? "infeasable" : item.solution
      return {
        jobId: item.jobId,
        status: item.status,
        solution
      }
    })

    this.updateChartData(solutionArray)
  }

  private updateChartData = (solutionArray: any) => {
    const tickArray: number[] = []
    const dataArray: any[] = []

    let maxX = 0
    let maxY = 0

    const step = (this.state.efMax - this.state.efMin) / this.state.efSteps
    let cur = this.state.efMin
    for (let i = 0; i < this.state.efSteps + 1; i++) {
      tickArray.push(this.state.efMin + step * i)
    }
    const currentProjects = toJS(this.injected.scenariosStore.working.projects)
    for (let i = 0; i < solutionArray.length; i++) {
      if (
        solutionArray[i].solution !== "infeasable" &&
        solutionArray[i].solution !== "error"
      ) {
        const solutionItem = solutionArray[i].solution.projects
        let benefit: number = 0
        let expenditure: number = 0
        let diffBase = Array()
        let diffLast = Array()
        for (
          let solutionIndex = 0;
          solutionIndex < solutionItem.length;
          solutionIndex++
        ) {
          const selectedProject = currentProjects.filter(
            (p: Project) => p.code === solutionItem[solutionIndex].project
          )[0]
          if (selectedProject !== undefined) {
            benefit += selectedProject.getBenefit()
            expenditure += projectAttributeTotal(selectedProject, "Expenditure")
            // const constraint = selectedProject.attributes.find(
            //   checkConstraint => checkConstraint.name === "Expenditure"
            // )
            // expenditure +=
            //   (constraint !== undefined ? constraint.value : 0) *
            //   selectedProject.duration
          } else {
            throw alert(" not found")
          }
        }
        if (benefit > maxY) {
          maxY = benefit
        }
        if (expenditure > maxX) {
          maxX = expenditure
        }
        diffBase = compareToProjects(
          solutionItem,
          this.injected.scenariosStore.working.projects
        )
        diffLast = []
        if(i > 0){
          diffLast = CompareDiffrence(diffBase, dataArray[i - 1].diffBase)
        }else{
          diffLast = CompareDiffrence(diffBase, [])
        }

        dataArray.push({
          name: `${i + 1}`,
          step: i + 1,
          x: expenditure,
          maxX: cur,
          y: benefit,
          diffBase,
          diffLast
        })
      } else {
        dataArray.push({
          name: `Error ${i + 1}`,
          step: i + 1,
          x: 0,
          maxX: cur,
          y: 0,
          diffBase: [],
          diffLast: []
        })
      }
      cur += step
    }
    const domain = [1, this.state.efSteps]

    this.setState({
      chartData: dataArray,
      ticks: tickArray,
      loading: false,
      loaded: true,
      domain
    })
    this.updateDimensions()
  }

  private compareToBaseToggle = (event: any, isInputChecked: boolean) => {
    this.setState({ compareToBase: isInputChecked })
  }

  public render(): JSX.Element {
    let body = <div />
    const formattedEfMax = accounting.formatMoney(this.state.efMax, "$", 0)
    const formattedEfMin = accounting.formatMoney(this.state.efMin, "$", 0)
    
    const canOptimise = this.injected.permissionStore.canOptimise()

    const CustomToolTipFN = (props: any) =>
      CustomToolTip(props, this.state.compareToBase)

    const compareToBaseLabel = this.state.compareToBase
      ? "Compare to Scenario"
      : "Compare to Previous Step"

    if (this.state.loading) {
      body = (
        <div style={styles.loading}>
          <CircularProgress size={200} thickness={7} />
        </div>
      )
    } else if (this.state.loaded) {
      body = (
        <CustomLineChart
          width={this.state.graphWidth}
          height={this.state.graphHeight}
          chartData={this.state.chartData}
          CustomToolTip={CustomToolTipFN}
          yAxisDataKey={"y"}
          yAxisLabel={this.state.YAxisLabel}
          xAxisDataKey={"x"}
          xAxisLabel={this.state.xAxisLabel}
          lines={[
            {
              dataKey: "y",
              name: "Efficient Frontier",
              stroke: "blue"
            }
          ]}
        />
      )
    }
    return (
      <div>
        <div id={"barDiv"}>
          <TextField
            floatingLabelText="Min Amount"
            name="minAmount"
            value={formattedEfMin}
            onChange={this.handleChange}
            style={styles.textField}
          />
          <TextField
            floatingLabelText={`Max Amount`}
            name="maxAmount"
            value={formattedEfMax}
            onChange={this.handleChange}
            style={styles.textField}
          />
          <TextField
            floatingLabelText="Steps"
            name="steps"
            value={this.state.efSteps}
            onChange={this.handleChange}
            style={styles.textField}
          />
          <Toggle
            label={compareToBaseLabel}
            toggled={this.state.compareToBase}
            onToggle={this.compareToBaseToggle}
            thumbStyle={styles.thumb}
            trackStyle={styles.track}
            thumbSwitchedStyle={styles.thumb}
            trackSwitchedStyle={styles.track}
          />
          <FlatButton
            label="Calculate"
            onClick={this.calculateEfficientFrontier}
            primary={true}
            disabled = {!canOptimise}
          />
        </div>
        <div id={"chartDiv"}>{body}</div>
      </div>
    )
  }
}

const styles: any = {
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}
