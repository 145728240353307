import * as React from "react"
import * as ReactDOM from "react-dom"
import { observer, inject } from "mobx-react"

import { snapTools } from "../SnapTools/snapTools"

import { usageInTimeslot, usageInTimeslotSingle } from "../../utils/mobxUtils"
import { areaToRadius } from "../../utils/portfolio/areaToRadius"

import { NumberBeautify } from "../../utils/NumberBeautify"
import Project from "../../mobx/Project"

import LoadingSymbol from "./LoadingSymbol"
import ScenariosStore from "../../mobx/ScenariosStore"
import { any } from "ramda"
import UiStore from "../../mobx/UiStore"

interface InjectedProps extends Props {
  scenariosStore: ScenariosStore
  uiStore: UiStore
}

/**
 * NOTE:
 * 
 * the lightGrey fill is a single path?
 * with project fills being a separate list of arc's overlayed 
 * 
 */

export interface Props {
  resource: string
  periods: any[]
  resourceIndex: string
  size: number
  maxLimit: number
  projectArray: Project[]
  currentPeriod: number
  selectedProject: string | undefined
  beginDisplayIndex: number
  endDisplayIndex: number
  showHoverbox: (content: any, title?: any) => void
  hideHoverbox(): void
  changeHoverboxPosition: (x: number, y: number) => void
}
export interface State {
  componentID: string
  displayLoading: boolean
}

@inject(({ store }) => {
  return {
    scenariosStore: store.scenariosStore,
    uiStore: store.uiStore
  }
})
class ResourceDial extends React.Component<Props, State> {
  public myState: any
  private ArcPastBackground = "Grey30"
  private ArcBackground = "#666666"
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      componentID: snapTools.createUniqueID(),
      displayLoading: true
    }
    this.myState = {
      componentID: "snapContainer",
      resource: this.props.resource,
      thisSnap: null,
      maxSize: this.props.size,
      padding: 10,
      scale: 1,
      currentJob: null,
      outerPath: {},
      projectSegments: [],
      timePeriodSegment: null,
      pieSpines: [],
      stateJobInfos: null,
      displayLoading: true,
      pieBackAnimation: null,
      projectSelectAnimation: null,
      frameRate: 30,
      animationTime: 0.5,
      arcArray: [],
      animationTick: 0,
      projectSelectTick: 0,
      domElement: null,
      lastBeginPeriod: 0
    }
  }

  public componentDidMount() {
    const thisDom = ReactDOM.findDOMNode(this) as any
    if (thisDom) {
      this.myState.domElement = thisDom.getElementsByTagName("svg")[0]
    }
    if (this.myState.thisSnap == null) {
      this.dialSetup()
    }
  }

  private getUsageForTimeslot = (index: number) => {
    const projectArray = this.injected.scenariosStore.working.projects
    // console.log("getusageForTimeslot")
    // console.log(this.injected.scenariosStore.baseline.projects[0].attributes)
    // const { projectArray, resourceIndex } = this.props
    const { resourceIndex } = this.props
    if (!projectArray || !projectArray.length) {
      console.log("NO PROJECTS")
      return 0
    }
    // console.log(projectArray)
    const area = usageInTimeslot(projectArray, index, resourceIndex)
    return area
  }

  private getCentre = () => {
    return {
      x: this.myState.maxSize / 2 + this.myState.padding,
      y: this.myState.maxSize / 2 + this.myState.padding
    }
  }

  private dialSetup = () => {
    this.setState({ displayLoading: false })

    const stepsDisplayed =
      this.props.endDisplayIndex - this.props.beginDisplayIndex
    this.myState.thisSnap = snapTools.createSnap(this.state.componentID)

    snapTools.createCircle(
      this.myState.thisSnap.snap,
      this.myState.maxSize,
      this.myState.padding,
      "#333"
    )

    const { maxLimit } = this.props

    const maxArea = areaToRadius(maxLimit, stepsDisplayed)

    this.myState.scale = this.myState.maxSize / 2 / maxArea

    const increment = 359.99 / (stepsDisplayed + 1)

    for (let i = 0; i < stepsDisplayed + 1; i++) {
      const thisPath = snapTools.pieSectorPath(
        this.getCentre(),
        0,
        this.myState.maxSize / 2,
        i * increment - 90,
        increment
      )
      const thisPathObject = snapTools.drawPath(
        this.myState.thisSnap.snap,
        thisPath,
        0.1,
        "rgba(0,0,0,0.01)",
        "#fff"
      )

      const thisID: any = i + 0

      const thisStateSnap = this.myState.thisSnap

      thisPathObject.mouseover(() => {
        thisStateSnap.customProperties.selectedTimeslot = parseInt(thisID) + 1
      })

      const thisPath2 = snapTools.arcLinePath(
        this.getCentre(),
        this.myState.maxSize / 2,
        i * increment - 90,
        increment
      )
      snapTools.drawPath(
        this.myState.thisSnap.snap,
        thisPath2,
        1,
        "none",
        "#fff"
      )
    }

    const arcArray: any[] = []

    for (
      let i = this.props.beginDisplayIndex;
      i <= this.props.endDisplayIndex;
      i++
    ) {
      const area = this.getUsageForTimeslot(i)

      const radius = areaToRadius(area, stepsDisplayed)

      this.myState.arcArray.push({
        currentSize: radius,
        oldSize: radius
      })

      arcArray.push(radius)
    }

    const outline = snapTools.pieLinkedOuterPath(
      this.getCentre(),
      arcArray,
      this.myState.scale
    )

    const innerLines = snapTools.pieDividingSegments(
      this.getCentre(),
      arcArray,
      this.myState.scale
    )

    this.myState.outerPath = snapTools.drawPath(
      this.myState.thisSnap.snap,
      outline,
      1,
      this.ArcBackground,
      "#fff"
    )

    this.myState.outerPath.attr("pointer-events", "none")

    for (
      let i = this.props.beginDisplayIndex;
      i <= this.props.endDisplayIndex;
      i++
    ) {
      const sectorPath = snapTools.pieSectorPath(
        this.getCentre(),
        0,
        0,
        (i - 1) * increment - 90,
        increment
      )

      const segment = snapTools.drawPath(
        this.myState.thisSnap.snap,
        sectorPath,
        1,
        "red",
        "red"
      )

      segment.attr("pointer-events", "none")

      this.myState.projectSegments.push({
        segment,
        currentSize: 0,
        oldSize: 0
      })
    }

    for (let i = 0; i < innerLines.length; i++) {
      this.myState.pieSpines.push(
        snapTools.drawPath(
          this.myState.thisSnap.snap,
          innerLines[i],
          0.4,
          "#333",
          "#fff"
        )
      )
    }
  }

  public componentDidUpdate() {
    const { maxLimit, selectedProject, currentPeriod } = this.props
    const stepsDisplayed =
      this.props.endDisplayIndex - this.props.beginDisplayIndex
    if (this.myState.thisSnap == null) {
      this.dialSetup()
    }

    const maxArea = areaToRadius(maxLimit, stepsDisplayed)

    this.myState.scale = this.myState.maxSize / 2 / maxArea

    const arcArray: any[] = []

    for (
      let i = this.props.beginDisplayIndex;
      i <= this.props.endDisplayIndex;
      i++
    ) {
      const area = this.getUsageForTimeslot(i)

      const radius = areaToRadius(area, stepsDisplayed)

      arcArray.push(radius)

      this.myState.arcArray[i - 1].oldSize = this.myState.arcArray[
        i - 1
      ].currentSize
    }

    this.myState.animationTick = 0

    if (this.myState.pieBackAnimation == null) {
      this.myState.pieBackAnimation = setInterval(
        () => this.animatePieBack(),
        1000 / this.myState.frameRate
      )
    }

    if (selectedProject) {
      for (
        let i = this.props.beginDisplayIndex;
        i <= this.props.endDisplayIndex;
        i++
      ) {
        this.myState.projectSegments[
          i - 1
        ].oldSize = this.myState.projectSegments[i - 1].currentSize
      }

      this.myState.projectSelectTick = 0

      if (this.myState.projectSelectAnimation == null) {
        this.myState.projectSelectAnimation = setInterval(
          () => this.animateProjectSelection(),
          1000 / this.myState.frameRate
        )
      }
    }
  }

  private animatePieBack = () => {
    if (this.myState.animationTick > 150) {
      if (this.myState.pieBackAnimation != null) {
        this.myState.animationTick = 0
        clearInterval(this.myState.pieBackAnimation)
        this.myState.pieBackAnimation = null
        return
      }
    }
    this.myState.animationTick += 1
    const stepsDisplayed =
      this.props.endDisplayIndex - this.props.beginDisplayIndex
    const timeToComplete = this.myState.animationTime
    const frameRate = this.myState.frameRate
    const timeByFrames = timeToComplete * frameRate

    const arcArray: any[] = []

    for (
      let i = this.props.beginDisplayIndex;
      i <= this.props.endDisplayIndex;
      i++
    ) {
      const area = this.getUsageForTimeslot(i)

      const radius = areaToRadius(area, stepsDisplayed)

      const goalAmount = radius
      let amount = this.myState.arcArray[i - 1].currentSize
      let moveAmount = Math.abs(
        this.myState.arcArray[i - 1].oldSize - goalAmount
      )

      if (moveAmount !== 0) {
        moveAmount = moveAmount / timeByFrames
      }
      const diff = this.myState.arcArray[i - 1].currentSize - goalAmount

      if (Math.abs(diff) >= moveAmount) {
        amount = diff >= 0 ? amount - moveAmount : amount + moveAmount
      } else {
        amount = goalAmount
      }

      this.myState.arcArray[i - 1].currentSize = amount

      arcArray.push(this.myState.arcArray[i - 1].currentSize)
    }

    const outline = snapTools.pieLinkedOuterPath(
      this.getCentre(),
      arcArray,
      this.myState.scale
    )

    this.myState.outerPath.attr("d", outline)

    const innerLines = snapTools.pieDividingSegments(
      this.getCentre(),
      arcArray,
      this.myState.scale
    )

    for (let i = 0; i < this.myState.pieSpines.length; i++) {
      this.myState.pieSpines[i].attr("d", innerLines[i])
    }
  }

  private animateProjectSelection = () => {
    const { selectedProject } = this.props
    if (this.myState.projectSelectTick > 150) {
      if (this.myState.projectSelectAnimation != null) {
        this.myState.projectSelectTick = 0
        clearInterval(this.myState.projectSelectAnimation)
        this.myState.projectSelectAnimation = null

        return
      }
    }

    this.myState.projectSelectTick += 1

    const timeToComplete = this.myState.animationTime
    const frameRate = this.myState.frameRate
    const timeByFrames = timeToComplete * frameRate

    const stepsDisplayed =
      this.props.endDisplayIndex - this.props.beginDisplayIndex
    const increment = 359.99 / (stepsDisplayed + 1)

    for (
      let i = this.props.beginDisplayIndex;
      i <= this.props.endDisplayIndex;
      i++
    ) {
      let timeSlotUsage = 0

      if (selectedProject) {
        const selectedProjectNew = this.props.projectArray.filter(
          project => project.code === selectedProject
        )[0]
        timeSlotUsage = usageInTimeslotSingle(
          selectedProjectNew,
          i,
          this.props.resourceIndex
        )
      }

      const radius = areaToRadius(timeSlotUsage, stepsDisplayed)

      const goalAmount = radius
      let amount = goalAmount
      const moveAmount =
        Math.abs(this.myState.projectSegments[i - 1].oldSize - goalAmount) /
        timeByFrames
      const diff = this.myState.projectSegments[i - 1].currentSize - goalAmount

      if (Math.abs(diff) >= moveAmount) {
        amount =
          diff >= 0
            ? this.myState.projectSegments[i - 1].currentSize - moveAmount
            : this.myState.projectSegments[i - 1].currentSize + moveAmount
      }

      this.myState.projectSegments[i - 1].currentSize = amount

      const sectorPath = snapTools.pieSectorPath(
        this.getCentre(),
        0,
        amount * this.myState.scale,
        (i - 1) * increment - 90,
        increment
      )

      this.myState.projectSegments[i - 1].segment.attr("d", sectorPath)
    }
  }

  public componentWillUnmount() {
    this.myState.thisSnap = null
    const el = document.getElementById(this.state.componentID)
    if (el !== null) {
      el.innerHTML = ""
    }
  }

  private searchSnap = () => {
    const { maxLimit, periods } = this.props
    const stepsDisplayed =
      this.props.endDisplayIndex - this.props.beginDisplayIndex

    if (typeof this.myState.thisSnap.customProperties !== "undefined") {
      if (
        typeof this.myState.thisSnap.customProperties.selectedTimeslot !==
        "undefined"
      ) {
        const thisUsage = this.getUsageForTimeslot(
          this.myState.thisSnap.customProperties.selectedTimeslot
        )
        const content = (
          NumberBeautify.numberFormat(thisUsage) +
          " / " +
          NumberBeautify.numberFormat(maxLimit)
        ).toString()

        this.props.showHoverbox(
          content,
          periods[this.myState.thisSnap.customProperties.selectedTimeslot - 1]
            .name
        )

        if (this.myState.domElement != null) {
          const degreePosition =
            (360 / stepsDisplayed) *
            this.myState.thisSnap.customProperties.selectedTimeslot +
            360 / stepsDisplayed / 2

          const thisPosition = this.myState.domElement.getBoundingClientRect()
          const centerCircle = this.getCentre()

          const dividingPosition = snapTools.positionAtAngle(
            centerCircle.x,
            degreePosition,
            this.myState.maxSize / 2 + 20
          )
          const x = thisPosition.left + dividingPosition.x + centerCircle.x
          const y = thisPosition.top + dividingPosition.y + centerCircle.y
          this.props.changeHoverboxPosition(x, y)
        }
      }
    }
  }

  public render(): JSX.Element {
    if( this.myState.lastBeginPeriod !== this.props.beginDisplayIndex && this.state.displayLoading == false){
      this.myState.lastBeginPeriod = this.props.beginDisplayIndex
      this.dialSetup()
    }
    return (
      <div style={{ position: "relative" }}>
        <LoadingSymbol inUse={this.myState.displayLoading}>
          <svg
            onMouseMove={this.searchSnap}
            onMouseOut={this.props.hideHoverbox}
            id={this.state.componentID}
            style={{
              width: this.myState.maxSize + this.myState.padding * 2,
              height: this.myState.maxSize + this.myState.padding * 2
            }}
          />
        </LoadingSymbol>
      </div>
    )
  }
}

export default ResourceDial
