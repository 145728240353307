import * as React from "react"
import { observer, inject } from "mobx-react"

import PermissionStore from "../../mobx/PermissionStore"
import { getCurrentScenarios, getScenario } from "../../utils/getData"

import Paper from "material-ui/Paper"
import FlatButton from "material-ui/FlatButton"
import Toggle from "material-ui/Toggle"
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
  } from "material-ui/Table"
import {MenuItem, SelectField } from "material-ui"

import CircularProgress from "material-ui/CircularProgress"
import Scenario, { ScenarioProps } from "../../mobx/Scenario"
import { compareScenario } from "../../utils/comparison"
import { loadAndFormatScenario } from "../../utils/loadData"
import { projectStatusToString } from "../../utils/projectState"
import { EnumType } from "typescript"
import DetailedComparison from "./comparisons/DetailedComparison"
import { SummaryComparison } from "./comparisons/SummaryComparison"




type Props = {}
interface InjectedProps extends Props {
    permissionStore: PermissionStore
}

export interface State {
  mounted: boolean
  loaded: boolean
  scenarios: any
  detailed: boolean
}

@inject(({store}) =>{
    return{
        permissionStore: store.permissionStore
    }
})

@observer
export class Comparison extends React.Component<Props,State> {
    private get injected(){
        return this.props as InjectedProps
    }
    constructor(props: Props){
        super(props)
        this.state = {
            mounted: false,
            loaded: false,
            scenarios: undefined,
            detailed: false
        }
    }

    public async componentDidMount() {
        await this.loadScenarioList()
        this.setState({ mounted: true })
    }
  
    //loads base details on projects
    private loadScenarioList = async () => {
      const { permissionStore } = this.injected
      
      if (permissionStore.user) {
        const token = permissionStore.getToken()
        const scenarioData = await getCurrentScenarios(token)
        this.setState({ scenarios: scenarioData.scenarios })   
      }
    }

    // changes to the summary view
    private toSummaryClick = (event: any) =>{
        this.setState({detailed : !this.state.detailed})
    }




    public render(): JSX.Element{
        if(!this.state.mounted){
            return <div/>
        }
        //declare consts
        const detailed = this.state.detailed
        const scenarios= this.state.scenarios
        const headingSummary = "Projects Summary"
        const headingDetailed = "Scenario Comparison"

        let body = <div/>
        let goTo = ""
        let heading = ""
        if(detailed){
            body = <DetailedComparison scenarios = {scenarios}/>
            goTo = "Go To " + headingSummary
            heading = headingDetailed
        }else{
            body = <SummaryComparison scenarios = {scenarios}/>
            goTo = "Go To " + headingDetailed
            heading = headingSummary
        }
        

        return(
            <div>
                <div style={styles.centered}>
                    <h1 style={{ marginBottom: "0" }}>Analysis Scenarios: {heading}</h1>
                    <FlatButton onClick={this.toSummaryClick} >{goTo}</FlatButton>
                </div>
                {body}
            </div>
        )
    }
}

const styles: any = {
    centered: {
        width: "40%", 
        margin: "auto",
        textAlign: "center"
    },
    row: {
      display: "flex",

    },
    twoColumn: {
        flex: "50%",
        marginTop:"50px",
        marginRight:"5px",
        marginLeft:"5px"
    },
    hidden: {
        display: "none",        
    },
    borderLeft:{
        borderLeft: "1px solid #E0E0E0"
    },
    table:{
        minHeight: "50px"
    }

  }

export default Comparison