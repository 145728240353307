import * as React from "react"

import FlatButton from "material-ui/FlatButton"
import { observer, inject } from "mobx-react"

import PermissionStore from "../../mobx/PermissionStore"
import { getLogo, getLogos } from "../../utils/getData"
import { saveLogo } from "../../utils/saveData"

import IconButton from "material-ui/IconButton"
import FileDownload from "material-ui/svg-icons/file/file-download"
import AddCircle from "material-ui/svg-icons/content/add-circle"
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
  } from "material-ui/Table"
import ConfigStore from "../../mobx/ConfigStore"
import { loadLogo } from "../../utils/loadData"
  

interface InjectedProps extends Props {
    permissionStore: PermissionStore
    configStore: ConfigStore
  }

  
export interface Props {}
export interface State {
    currentLogo?: number
    logoIds: any[]
    logos: any[]
    
  uploadImg: any
  uploadFile: any
}

@inject(({ store }) => {
  return {
    permissionStore: store.permissionStore,
    configStore: store.configStore
  }
})

@observer
export class LogoSelection extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }

  constructor(props: Props) {
    super(props)
    this.state = {
        currentLogo: undefined,
        logoIds: [],
        logos: [],
      uploadImg:undefined,
      uploadFile: undefined
    }
  }

  /**
   * 
   */
  public async componentDidMount(){
    this.refreshLogoList()
  }

  /**
   * 
   * @returns 
   */
  private uploadImage = () =>{
    let uploadedFile = this.state.uploadFile
    
    if(uploadedFile == undefined){
      return
    }
    let fd = new FormData()
    fd.append('logo',uploadedFile)
    console.log(uploadedFile)
    console.log(fd.getAll('logo'))
    fetch(`${process.env.REACT_APP_API_URL}/image/logo`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.injected.permissionStore.getToken()
      },
      body: fd
    })
      .then(async result => {
        console.log(result.json())
        this.refreshLogoList()
      })
      .catch( error => {
        console.error(error)
      }
  )
  }
   /**
   *
   */
    private handleFileUpload = (event: any) => {
        const maxFileSize = 2000000
        //check to make sure we have a file
        if(event.target.files ===0){
          alert('Please upload a valid file')
          return // end early
        }
        const file = event.target.files[0]
      
        //check for max size
        if(file.size > maxFileSize){
          alert('Max upload size is 2 Megabytes')
        }
    
        //check we have being given an Image, this is a basic check an could be adjusted to give more control over acceptable types
        let basetype = file.type.split("/") 
        if(basetype[0] != "image" ){
          alert('Please upload an image')
        }
    
        this.setState({uploadImg: URL.createObjectURL(event.target.files[0]), uploadFile:event.target.files[0]})
      }
    
      /**
       * refreshes the list of logos from the server, will retrieve all logos
       */
      private refreshLogoList = async () =>{
        let logos = await getLogos(this.injected.permissionStore.getToken())
        
        this.setState({logoIds:logos.results, uploadImg: undefined})
    }

    private downloadLogo = async (name: string) => {
        let imageBlob = await getLogo(this.injected.permissionStore.getToken(), name)
        //setup file properties
        imageBlob.name = name
        imageBlob.lastModifiedDate = new Date()
        //get file type
        let split  = name.split('.')
        let type = "image/" + split[split.length-1]
        if(type == "image/svg"){
          type = "image/svg+xml"
        }

        let imageFile= new File([imageBlob], name, {type: type})
        let logoIndex = this.state.logos.findIndex((logo: any) => logo.name === imageFile.name)
        if( logoIndex !== -1){
          this.state.logos[logoIndex] = imageFile
        }else{
        this.state.logos.push(imageFile)
        }
        this.forceUpdate()
    }

    private setOrgLogo = async (name: string ) =>{
      this.injected.configStore.primaryLogo = name
      let logo = await loadLogo(name, this.injected.permissionStore.getToken())
      this.injected.configStore.primaryLogoURL = URL.createObjectURL(logo)
    }
    private setPartnerLogo = async (name: string ) =>{
      this.injected.configStore.partnerLogo = name
      let logo = await loadLogo(name, this.injected.permissionStore.getToken())
      this.injected.configStore.partnerLogoURL = URL.createObjectURL(logo)
    }

  public render(): JSX.Element{
      
    const uploadedFile = this.state.uploadImg
    let selectedLogo = ""
    if(uploadedFile != undefined){
      selectedLogo = uploadedFile
    }

      return(
          <div>
              <h3>Company Logo</h3>

            <Table>
                <TableHeader  displaySelectAll={false} adjustForCheckbox={true}>
                    <TableRow>
                        <TableHeaderColumn>
                            Name
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Preview
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Make Organization Logo
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                            Make Partner Logo
                        </TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {this.state.logoIds.map((logo, i) =>{
                        const onSelectOrgLogo = () =>{
                          this.setOrgLogo(logo)
                        }
                        const onSelectPartnerLogo = () =>{
                          this.setPartnerLogo(logo)
                        }
                        const OnPreviewLogo = () =>{
                            this.downloadLogo(logo)
                        }
                        const icon = this.state.currentLogo === logo ? (
                            <div />
                          ) : (
                            <AddCircle />
                          )
                        let imageIndex  = this.state.logos.findIndex((image: any) => image.name === logo)

                        const preview = imageIndex != -1 ? (
                          <img
                           src={URL.createObjectURL(this.state.logos[imageIndex])}
                            style={{  maxHeight: "500px"}}
                          />
                        ) : (
                          <IconButton onClick={OnPreviewLogo}><FileDownload/></IconButton>
                        )
                        return (
                            <TableRow key={i}>
                              <TableRowColumn>{logo}</TableRowColumn>
                              <TableRowColumn>
                                {preview}
                              </TableRowColumn>
                              <TableRowColumn>
                                <IconButton onClick={onSelectOrgLogo}>{icon}</IconButton>
                              </TableRowColumn> 
                              <TableRowColumn>
                                <IconButton onClick={onSelectPartnerLogo}>{icon}</IconButton>
                              </TableRowColumn>
                            </TableRow>
                        )
                        })    
                    }
                </TableBody>

            </Table>
            <h4>Upload New Logo</h4>
        <input
                    name="myFile"
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    onChange={this.handleFileUpload}
        />

        <img id="logo_preview" src={selectedLogo} style={{display:selectedLogo ===""?"none":"block" }}/>
      
        <FlatButton onClick={this.uploadImage} disabled={selectedLogo ===""} secondary={true} >
          Confirm and Upload Image
        </FlatButton>
          </div>
      )
  }

}