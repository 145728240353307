import * as React from "react"
import { observer, inject } from "mobx-react"
import { toJS } from "mobx"

import UiStore from "../mobx/UiStore"
import PermissionStore from "../mobx/PermissionStore"
import ScenariosStore from "../mobx/ScenariosStore"
import BaselineStore from "../mobx/BaselineStore"
import ConfigStore from "../mobx/ConfigStore"
import { OptimisationParameter } from "../mobx/Scenario"

import TextField from "material-ui/TextField"
import FlatButton from "material-ui/FlatButton"
import SelectField from "material-ui/SelectField"
import MenuItem from "material-ui/MenuItem"
import Toggle from "material-ui/Toggle"
import Dialog from "material-ui/Dialog"
import { findIndex, first } from "lodash"
import { status } from "../mobx/Project"
import { timePeriodChecks } from "../utils/mobxUtils"

export interface State {
  oldScenarioName: string
  oldScenarioDescription: string
  edited: boolean
  ConfirmShow: boolean
  changes: any
  newCurrent: number
}

export interface Props { }

interface InjectedProps extends Props {
  permissionStore: PermissionStore
  uiStore: UiStore
  scenariosStore: ScenariosStore
  baselineStore: BaselineStore
  configStore: ConfigStore
}
@inject(({ store }) => {
  return {
    configStore: store.configStore,
    permissionStore: store.permissionStore,
    uiStore: store.uiStore,
    scenariosStore: store.scenariosStore,
    baselineStore: store.baselineStore
  }
})
@observer
export class ScenarioSettingsTab extends React.Component<Props, State> {
  private get injected() {
    return this.props as InjectedProps
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      oldScenarioName: "",
      oldScenarioDescription: "",
      edited: false,
      ConfirmShow: false,
      changes: [],
      newCurrent:1

    }
  }

  public componentWillReceiveProps(nextProps: any) {
    if (!this.injected.uiStore.showScenarioSettingsDialog) {
      this.setState({
        oldScenarioName: nextProps.scenarioStore.working.name,
        oldScenarioDescription: nextProps.scenarioStore.working.description,
        edited: false
      })
    }
  }

  public componentDidMount() {
    const selectedScenario = this.injected.scenariosStore.working
    this.setState({
      oldScenarioName: selectedScenario.name,
      oldScenarioDescription: selectedScenario.description || "",
      edited: false
    })
  }

  private resetScenario = () => {
    this.injected.scenariosStore.working.name = this.state.oldScenarioName
    this.injected.scenariosStore.working.description = this.state.oldScenarioDescription

    this.setState({ edited: false })
  }

  private onScenarioNameChange = (event: any) => {
    this.injected.scenariosStore.working.name = event.target.value
    this.injected.scenariosStore.edit()
    this.setState({ edited: true })
  }

  private onScenarioDescriptionChange = (event: any) => {
    this.injected.scenariosStore.working.description = event.target.value
    this.injected.scenariosStore.edit()
    this.setState({ edited: true })
  }
  /**
    * updates the scenarios current period
    * 0 indexed (vs project duration which is 1 indexed)
    */
  private onCurrentPeriodChange = (event: any, index: any, value: string) => {
    // need to check what projects will be in-progress and which will be completed
    let NewCurrent = parseInt(value, 10)
    //calling with update false to just get list of projects
    const projects = this.injected.scenariosStore.working.projects
    let Changes = timePeriodChecks(NewCurrent +1 , projects)
    // let Changes = this.injected.scenariosStore.working.timePeriodChecks(NewCurrent)
    if(Changes.length >0){
      //get confirmation before updating if will change projects
      this.setState({newCurrent: NewCurrent, changes: Changes,ConfirmShow: true})
    }else{
      
      //no project changes, just update period
      this.updateCurrentPeriod(NewCurrent)
    }
  }

  /**
   * A separate setter to be used by onCurrentPeriodChange and confirmPeriodChange
   * @param newCurrentPeriod the new period to be set as currentPeriod
   */
  private updateCurrentPeriod =(newCurrentPeriod : number )=>{
    //update the projects
    const projects = this.injected.scenariosStore.working.projects
    for(let p = 0; p < projects.length; p++){
      let i = this.state.changes.findIndex((c: {code:string, status:status}) => c.code === projects[p].code)
      if(i != -1 ){
        console.log("updating - " + projects[p].name + " to " + this.state.changes[i].status)
        this.injected.scenariosStore.working.projects[p].status = this.state.changes[i].status
      }
    }
    //update the current period
    this.injected.scenariosStore.working.currentPeriod = newCurrentPeriod;
    this.injected.scenariosStore.edit()
    this.setState({ edited: true })
  }

  private confirmPeriodChange =()=>{
    this.setState({ConfirmShow:false})
    this.updateCurrentPeriod(this.state.newCurrent)
  }
  private cancelPeriodChange = ()=>{
    this.setState({ConfirmShow:false})
  }

  private onParameterChange = (event: any, index: any, value: any) => {
    this.injected.scenariosStore.working.optimisationParameter = value
  }

  private onConstraintChange = (event: any, index: any, value: string) => {
    this.injected.scenariosStore.working.optimisationAttribute = value
  }

  private onOptimizationModeChange = (event: any) => {
    if (this.injected.scenariosStore.working.optimisationMode == "Max") {
      this.injected.scenariosStore.working.optimisationMode = "Min"
    } else {
      this.injected.scenariosStore.working.optimisationMode = "Max"
    }
  }

  private onShowChange = (event: any) => {
    this.injected.uiStore.showProjectValue = !this.injected.uiStore
      .showProjectValue
  }

  public render(): JSX.Element {
    const {
      name,
      description,
      currentPeriod,
      optimisationMode,
      optimisationAttribute,
      optimisationParameter,
      projects
    } = this.injected.scenariosStore.working
    const periods = toJS(this.injected.baselineStore.periods)
    let { showProjectValue } = this.injected.uiStore

    let attributeSelector = <div />
    if (optimisationParameter == "constraint") {
      attributeSelector = (
        <SelectField
          floatingLabelText="Constraint"
          onChange={this.onConstraintChange}
          value={optimisationAttribute}
        >
          {this.injected.baselineStore.constraints.map(constraint => {
            return (
              <MenuItem value={constraint.name} primaryText={constraint.name} />
            )
          })}
        </SelectField>
      )
    }

    const confirmActions = [
      <FlatButton key="confirmActionYes" label="Confirm"
      primary={true} onClick={this.confirmPeriodChange}/>,

      <FlatButton key="confirmActionCancel" label ="Cancel"
      primary={true} onClick={this.cancelPeriodChange}/>
    ]

    return (
      <div>
        <div>
          <div style={{
            display: "flex",
            flex: "0 0 65vh",
          }}>
            <TextField
              floatingLabelText="Scenario Name"
              value={name}
              onChange={this.onScenarioNameChange}
              style={{ width: "45%", marginRight: 5 }}
            />
            <br />
            <TextField
              floatingLabelText="Scenario Description"
              style={{ width: "45%" }}
              value={description}
              onChange={this.onScenarioDescriptionChange}
              multiLine={true}
              rows={1}
              rowsMax={3}
            />
          </div>
          <SelectField
            floatingLabelText="Portfolio Planning Horizon"
            value={currentPeriod}
            onChange={this.onCurrentPeriodChange}
            autoWidth={true}
          >
            {periods.map((item: any, index: number) => (
              <MenuItem
                key={index}
                value={index}
                primaryText={item.name}
              />
            ))}
          </SelectField>

        </div>

        <Toggle
          label="Show Optimization Value"
          onToggle={this.onShowChange}
          toggled={showProjectValue}
        ></Toggle>
        <br />
        <h2>Optimization Settings</h2>
        <SelectField
          floatingLabelText="Optimization mode"
          onChange={this.onParameterChange}
          value={optimisationParameter}
        >
          {Object.keys(OptimisationParameter).map(key => {
            return <MenuItem value={key} primaryText={key} />
          })}
        </SelectField>
        {attributeSelector}
        <div> Optimization Approach </div>
        <Toggle
          label={optimisationMode}
          onToggle={this.onOptimizationModeChange}
          toggled={optimisationMode == "Max"}
        ></Toggle>
        <div>
          <FlatButton
            label="Undo"
            onClick={this.resetScenario}
            disabled={!this.state.edited}
          />
        </div>
      <Dialog open={this.state.ConfirmShow} title = {"Please Confirm Period Change"} actions={confirmActions}>
        <div>Set Current period to {periods[this.state.newCurrent].name}</div>
        <div>This will effect {this.state.changes.length} projects status</div>
      </Dialog>
      </div>
    )
  }
}
